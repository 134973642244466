import { FormControl, MenuItem, Select } from "@material-ui/core";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";
import { InputGroup } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React from "react";
import { convertGBPtoPoundSymbol } from "../../../../../utils/commonFunction";

export const PromotionForm = ({
  intl,
  formik,
  type,
  // handleCountry,
  // detailData,
  // franchiseList,
  // handleAllFranchise,
  // handleFranchise,
}) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "Offer.Code" })}</label>
          <input
            type="text"
            className="form-control"
            placeholder={intl.formatMessage({ id: "Offer.Code" })}
            name="offer_code"
            {...formik.getFieldProps("offer_code")}
          />
          {formik.touched.offer_code && formik.errors.offer_code ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.offer_code}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-md-12">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "Offer.Description" })}</label>
          <input
            type="text"
            className="form-control"
            placeholder={intl.formatMessage({
              id: "Offer.Description",
            })}
            name="offer_description"
            {...formik.getFieldProps("offer_description")}
          />
          {formik.touched.offer_description &&
          formik.errors.offer_description ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.offer_description}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "Offer.Type" })}</label>
          <FormControl fullWidth>
            <Select
              disabled={type === "edit" ? true : false}
              name="offer_type"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              className="form-control"
              IconComponent={(props) => (
                <i {...props}>
                  <KeyboardArrowDownIcon />
                </i>
              )}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              {...formik.getFieldProps("offer_type")}
            >
              <MenuItem value="">
                {intl.formatMessage({ id: "Offer.Type" })}
              </MenuItem>
              <MenuItem value="percentage">
                {intl.formatMessage({ id: "Percentage" })}
              </MenuItem>
              <MenuItem value="fixed_amount">
                {intl.formatMessage({ id: "Fixed.Amount" })}
              </MenuItem>
            </Select>
          </FormControl>
          {formik.touched.offer_type && formik.errors.offer_type ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.offer_type}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-md-2">
        <div className="form-groupB">
          <label>&nbsp;</label>
          <input
            name="currency_code"
            type="text"
            className="form-control"
            placeholder={"£"}
            disabled
            value={
              formik?.values?.currency_code
                ? convertGBPtoPoundSymbol(formik?.values?.currency_code)
                : ""
            }
            onChange={(e) =>
              formik.setFieldValue("currency_code", e.target.value)
            }
            // {...formik.getFieldProps("currency_code")}
          />

          {formik.touched.currency_code && formik.errors.currency_code ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.currency_code}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "Value" })}</label>
          {formik?.values?.offer_type === "percentage" ? (
            <i
              style={{
                position: "absolute",
                top: `${
                  formik.touched.value && formik.errors.value ? "42%" : "58%"
                }`,
                transform: "translateY(-50%)",
                right: "28px",
              }}
              className="fa fa-percent"
              aria-hidden="true"
            ></i>
          ) : (
            ""
          )}

          <input
            name="value"
            type="text"
            disabled={type === "edit" ? true : false}
            className="form-control"
            placeholder={intl.formatMessage({ id: "Value" })}
            {...formik.getFieldProps("value")}
          />
          {formik.touched.value && formik.errors.value ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.value}</div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "Apply.On" })}</label>
          <FormControl fullWidth>
            <Select
              name="apply_on"
              disabled={type === "edit" ? true : false}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              className="form-control"
              IconComponent={(props) => (
                <i {...props}>
                  <KeyboardArrowDownIcon />
                </i>
              )}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              {...formik.getFieldProps("apply_on")}
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="invoice_amount">
                {intl.formatMessage({ id: "Invoice.Amount" })}
              </MenuItem>
              <MenuItem value="each_specified_item">Storage Item</MenuItem>
            </Select>
          </FormControl>
          {formik.touched.apply_on && formik.errors.apply_on ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.apply_on}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-md-6"></div>
      <div className="col-md-4">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "Duration.Type" })}</label>
          <FormControl fullWidth>
            <Select
              name="duration_type"
              disabled={type === "edit" ? true : false}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              className="form-control"
              IconComponent={(props) => (
                <i {...props}>
                  <KeyboardArrowDownIcon />
                </i>
              )}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              {...formik.getFieldProps("duration_type")}
            >
              <MenuItem value="">
                {intl.formatMessage({ id: "Duration.Type" })}
              </MenuItem>
              <MenuItem value="once">
                {intl.formatMessage({ id: "One.Time" })}
              </MenuItem>
              <MenuItem value="forever">
                {intl.formatMessage({ id: "Forever" })}
              </MenuItem>
              <MenuItem value="repeating">
                {intl.formatMessage({ id: "Multiple.Months" })}
              </MenuItem>
            </Select>
          </FormControl>
          {formik.touched.duration_type && formik.errors.duration_type ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.duration_type}</div>
            </div>
          ) : null}
        </div>
      </div>
      {console.log("formikM", formik.values)}
      <div className="col-md-4">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "Duration" })}</label>
          <input
            name="duration"
            type="number"
            className="form-control"
            placeholder={
              formik.values.duration_type === "repeating" ? "Months" : ""
            }
            disabled={
              type === "edit"
                ? true
                : formik.values.duration_type === "repeating"
                ? false
                : true
            }
            min={0}
            step={1}
            {...formik.getFieldProps("duration")}
          />
          {formik.touched.duration && formik.errors.duration ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.duration}</div>
            </div>
          ) : null}
        </div>
      </div>
      {/* <div className="col-md-4">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "Duration.Length" })}</label>
          <FormControl fullWidth>
            <Select
              name="duration_length"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              className="form-control"
              IconComponent={(props) => (
                <i {...props}>
                  <KeyboardArrowDownIcon />
                </i>
              )}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              {...formik.getFieldProps("duration_length")}
              disabled={
                formik.values.duration_type === "limited_period" ? false : true
              }
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="day">
                {intl.formatMessage({ id: "Day(s)" })}
              </MenuItem>
              <MenuItem value="week">
                {intl.formatMessage({ id: "Week(s)" })}
              </MenuItem>
              <MenuItem value="month">
                {intl.formatMessage({ id: "Month(s)" })}
              </MenuItem>
              <MenuItem value="year">
                {intl.formatMessage({ id: "Year(s)" })}
              </MenuItem>
            </Select>
          </FormControl>
          {formik.touched.duration_length && formik.errors.duration_length ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.duration_length}
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="col-md-4">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "Valid.To" })}</label>
          <InputGroup className="calendr">
            <DatePicker
              name="valid_till"
              fullWidth
              fixedHeight
              className="form-control"
              wrapperClassName="datepicker"
              dateFormat="dd-MM-Y"
              // value={formik.values.valid_till}
              selected={formik.values.valid_till * 1000}
              onChange={(date) =>
                formik.setFieldValue(
                  "valid_till",
                  new Date(date).getTime() / 1000
                )
              }
              minDate={new Date()}
              placeholderText="DD-MM-YYYY"
            />
            {formik.touched.valid_till && formik.errors.valid_till ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.valid_till}</div>
              </div>
            ) : null}
            <CalendarTodayIcon />
          </InputGroup>
        </div>
      </div> */}
      {/* <div className="col-12 col-sm-8">
        <div className="form-groupB">
          <label>{intl.formatMessage({ id: "COUNTRY" })}</label>
          <FormControl fullWidth>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              className="form-control"
              IconComponent={(props) => (
                <i {...props}>
                  <KeyboardArrowDownIcon />
                </i>
              )}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              name="country"
              onChange={handleCountry}
              value={formik.values.country}
            >
              <MenuItem value="">
                {intl.formatMessage({ id: "COUNTRY" })}
              </MenuItem>
              {detailData &&
                detailData.map((data, index) => (
                  <MenuItem value={data.id} key={index}>
                    {data.name}&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    <span style={{ color: "lightgrey" }}>{data.timezone}</span>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {formik.touched.country && formik.errors.country ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.country}</div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="col-md-12 mt-10">
        <span className="switch switch-outline switch-icon switch-primary row">
          <span className="col-8 col-md-3">
            {intl.formatMessage({ id: "All.Franchises" })}
          </span>
          <label className="col-3 col-md-3">
            <input
              type="checkbox"
              name="is_all_franchise"
              checked={
                formik.values.franchise_charbee_key.length === 0
                  ? false
                  : formik.values.franchise_charbee_key.length ===
                    franchiseList?.length
              }
              disabled={franchiseList && !franchiseList?.length}
              // {...formik.getFieldProps("is_all_franchise")}
              onChange={(e) => handleAllFranchise(e)}
            />
            <span></span>
            {formik.touched.is_all_franchise &&
            formik.errors.is_all_franchise ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.is_all_franchise}
                </div>
              </div>
            ) : null}
          </label>
        </span>
      </div>
      {formik.values.country && franchiseList && franchiseList?.length
        ? franchiseList.map((data, index) => {
            const charge = data.chargebee_key ? data.chargebee_key : data.id;
            const datas = formik.values.franchise_charbee_key.find(
              (element) => element === charge
            );
            return (
              <div className="col-md-12" key={index}>
                <span className="switch switch-outline switch-icon switch-primary row">
                  <span className="col-8 col-md-3">{data.name}</span>
                  <label className=" col-3 col-md-3">
                    <input
                      type="checkbox"
                      name="is_all_franchise"
                      checked={datas ? true : false}
                      onChange={(e) => handleFranchise(e, data)}
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            );
          })
        : ""} */}
    </div>
  );
};

export default PromotionForm;
