import axios from "axios";
export const ASSIGN_AVAILABILITY = "user/assign/agent-availability";

//api for country deletion
export function assignAvailability(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + ASSIGN_AVAILABILITY, data);
}

export function erReport(data) {
  return axios.get(process.env.REACT_APP_SITE_URL + data);
}
