import * as requestFromServer from "./InvoiceCrud";
import { InvoiceSlice, callTypes } from "./InvoiceSlice";

const { actions } = InvoiceSlice;

// Action Creator: fetches a list of tasks from the server
export const fetchInvoice = (queryParams, nextoffset) => (dispatch) => {
  // Dispatch action to indicate an API call has started
  dispatch(actions.startCall({ callType: callTypes.list }));
  // Dispatch action to update the task list with loading state
  dispatch(
    actions.tasksFetched({ totalCount: 0, entities: [], loading: true })
  );
  // Make a GET request to the server to fetch tasks
  return requestFromServer
    .findTasks(queryParams, nextoffset)
    .then((response) => {
      // Extract meta data and task list from server response
      const { total, data, nextoffset } = response.data;
      // Dispatch action to update the task list with the fetched data
      dispatch(
        actions.tasksFetched({
          totalCount: total,
          entities: data,
          nextoffset: nextoffset,
          loading: false,
        })
      );
    })
    .catch((error) => {
      // Add a client message to the error object
      error.clientMessage = "Can't find products";
      // Dispatch action to handle the error
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSub = (queryParams, nextoffset, fraId) => (dispatch) => {
  // Dispatch action to indicate an API call has started
  dispatch(actions.startCall({ callType: callTypes.list }));
  // Dispatch action to update the task list with loading state
  dispatch(actions.subFetched({ totalCount: 0, entities: [], loading: true }));
  // Make a GET request to the server to fetch tasks
  return requestFromServer
    .findSub(queryParams, nextoffset, fraId)
    .then((response) => {
      // Extract meta data and task list from server response
      const { total, data, nextoffset } = response.data;
      // Dispatch action to update the task list with the fetched data
      dispatch(
        actions.subFetched({
          totalCount: total,
          entities: data,
          nextoffset: nextoffset,
          loading: false,
        })
      );
    })
    .catch((error) => {
      // Add a client message to the error object
      error.clientMessage = "Can't find products";
      // Dispatch action to handle the error
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCreditInvoice = (queryParams, nextoffset) => (dispatch) => {
  // Dispatch action to indicate an API call has started
  dispatch(actions.startCall({ callType: callTypes.creditlist }));
  // Dispatch action to update the task list with loading state
  dispatch(
    actions.creditFetched({ totalCount: 0, entities: [], loading: true })
  );
  // Make a GET request to the server to fetch tasks
  return requestFromServer
    .findCredit(queryParams, nextoffset)
    .then((response) => {
      // Extract meta data and task list from server response
      const { total, data, nextoffset } = response.data;
      // Dispatch action to update the task list with the fetched data
      dispatch(
        actions.creditFetched({
          totalCount: total,
          entities: data,
          nextoffset: nextoffset,
          loading: false,
        })
      );
    })
    .catch((error) => {
      // Add a client message to the error object
      error.clientMessage = "Can't find products";
      // Dispatch action to handle the error
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
// Action Creator: searches for a task on the server
export const fetchSearchTask = (queryParams) => (dispatch) => {
  // Dispatch action to indicate an API call has started
  dispatch(actions.startCall({ callType: callTypes.list }));
  // Make a GET request to the server to search for a task
  return requestFromServer
    .searchTaskApi(queryParams)
    .then((response) => {
      // Extract the task data from the server response
      const { data } = response.data;
      // Dispatch action to update the task list with the search results
      dispatch(actions.searchTask({ data }));
    })
    .catch((error) => {
      // Add a client message to the error object
      error.clientMessage = "Can't find products";
      // Dispatch action to handle the error
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchReset = (queryParams) => (dispatch) => {
  // Dispatch action to indicate an API call has started
  dispatch(actions.reset());
};

export const fetchInvoiceReport = (queryParams, nextoffset, fraId, role) => (
  dispatch
) => {
  // Dispatch action to indicate an API call has started
  dispatch(actions.startCall({ callType: callTypes.list }));
  // Dispatch action to update the task list with loading state
  dispatch(
    actions.invoiceReportFetched({ totalCount: 0, entities: [], loading: true })
  );
  // Make a GET request to the server to fetch tasks
  return requestFromServer
    .findInvoiceReport(queryParams, nextoffset, fraId, role)
    .then((response) => {
      // Extract meta data and task list from server response
      const { total, data, nextoffset } = response.data;
      // Dispatch action to update the task list with the fetched data
      dispatch(
        actions.invoiceReportFetched({
          totalCount: total,
          entities: data,
          nextoffset: nextoffset,
          loading: false,
        })
      );
    })
    .catch((error) => {
      // Add a client message to the error object
      error.clientMessage = "Can't find products";
      // Dispatch action to handle the error
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
