// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html

import * as uiHelpers from "../InvoiceUIHelpers";

import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  getHandlerTableChange,
  getSelectRow,
  getSelectRowChargeIn,
  getSelectRowChargePromo,
  sortCaret,
} from "../../../../_metronic/_helpers";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import NoRecord from "../../../../_metronic/_partials/widgets/NoRecord";
import NotAuthorized from "../../../../_metronic/_partials/widgets/NotAuthorized";
import { Pagination } from "../../../../_metronic/_partials/controls";
// import { fetchAgent } from "../../ContactDeals/_redux/contact/contactAction";
import {
  fetchInvoice,
  fetchInvoiceReport,
  fetchReset,
  fetchSub,
} from "../_redux/InvoiceAction";
import moment from "moment";
import { useIntl } from "react-intl";
import { useInvoiceUIContext } from "../InvoiceUIContext";
import ContactDealTaskModal from "../../../../_metronic/_partials/widgets/alert/ContactDealTaskModal";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useHistory } from "react-router-dom";
import { findTasksExport, viewPdfInvoice } from "../_redux/InvoiceCrud";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";

const InvoiceReportTable = ({
  show,
  setShow,
  permission,
  active,
  setActive,
}) => {
  const intl = useIntl();
  const InvoiceUIContext = useInvoiceUIContext();
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const [dialog, setDialog] = useState({ type: "", open: false, id: "" });
  let history = useHistory();
  const TasksUIProps = useMemo(() => {
    return {
      ids: InvoiceUIContext?.ids,
      setIds: InvoiceUIContext?.setIds,
      queryParams: InvoiceUIContext?.queryParams,
      setQueryParams: InvoiceUIContext?.setQueryParams,
      openEditContactPage: InvoiceUIContext?.openEditContactPage,
      openDeleteContactDialog: InvoiceUIContext?.openDeleteContactDialog,
    };
  }, [InvoiceUIContext]);

  const dispatch = useDispatch();

  const { currentState, role, userData } = useSelector(
    (state) => ({
      currentState: state.invoice.listInvoiceReport,
      role: state.auth.user.roles,
      userData: state.auth.user,
    }),
    shallowEqual
  );

  console.log("currentState", currentState);
  const { entities, nextoffset, totalCount, loading } = currentState;

  useEffect(() => {
    if (active) {
      setSnack({ call: false, type: "", message: "" });
      findTasksExport(TasksUIProps.queryParams, TasksUIProps?.ids)
        .then((res) => {
          setSnack({ call: true, type: "success", message: res.data.message });

          // window.open(res.data?.data.download_url, '_blank');
          setActive(false);
        })
        .catch((error) => {
          setSnack({
            call: true,
            type: "error",
            message: error.response.data.message,
          });
          setActive(false);
        });
    }
  }, [active]);

  useEffect(() => {
    return () => {
      dispatch(fetchReset());
    };
  }, []);

  useEffect(() => {
    // clear selections list
    // server call by queryParams
    // TasksUIProps.setIds([]);

    if (role && role?.length && role[0] === "franchise-owner") {
      // if(TasksUIProps.queryParams?.filter.search_franchise){

      dispatch(
        fetchInvoiceReport(
          TasksUIProps.queryParams,
          TasksUIProps?.ids,
          userData?.franchise_id,
          role && role?.length && role[0]
        )
      );
      // }
    } else {
      dispatch(
        fetchInvoiceReport(
          TasksUIProps.queryParams,
          TasksUIProps?.ids,
          "",
          role && role?.length && role[0]
        )
      );
    }
    // dispatch(fetchAgent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TasksUIProps.queryParams, dispatch]);
  console.log("TasksUIProps", TasksUIProps);

  // useEffect(() => {
  //   TasksUIProps.setQueryParams(uiHelpers.initialFilter);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch]);

  const columns = [
    {
      dataField: "date",
      text: "Date",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => (
        <>
          <span>{moment(e).format("DD-MM-YYYY")}</span>
        </>
      ),
    },
    {
      dataField: "invoice_created",
      text: "T. Invoices Created",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "franchise_name",
      text: "Fran Name",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "invoice_success",
      text: "T. Inv Success",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "revenue_collected",
      text: "T. Revenue Collected from Cust.(£)",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => (
        <span>
          {new Intl.NumberFormat("en-UK", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(e)}
        </span>
      ),
    },
    {
      dataField: "payment_success",
      text: "T. Payment Success",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "payment_failure",
      text: "T. Payment Failure (Open/Void/Failed)",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "creditnote_processed",
      text: "T. Credit Notes Processed",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "refund_amount",
      text: "T. Refunds Amount",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "franchise_payment_processed",
      text: "T. Franc. Payments Processed",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "franchise_payment_amount",
      text: "T. Fran. Payments Amount(£)",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => (
        <span>
          {new Intl.NumberFormat("en-UK", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(e)}
        </span>
      ),
    },
    {
      dataField: "msf_payment_processed",
      text: "T. MSF+ Processed",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "msf_payment_amount",
      text: "T. MSF+ Processed Amount(£)",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => (
        <span>
          {new Intl.NumberFormat("en-UK", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(e)}
        </span>
      ),
    },
    {
      dataField: "qbo_invoice_sync_success",
      text: "T. Invoices Synced to Fran.QBO",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "qbo_invoice_sync_failure",
      text: "T. Invoices Sync Failure to fran. QBO",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "qbo_creditnote_sync_success",
      text: "T. Credit Note Synced to Fran.QBO",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "qbo_creditnote_sync_failure",
      text: "T. Credit Note Sync Failure to fran. QBO",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "qbo_bill_sync_success",
      text: "T. MSF+ Bill Synced to Fran.QBO",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "qbo_bill_sync_failure",
      text: "T. MSF+ Bill Sync Failure to fran. QBO",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "total_payout_to_stripe",
      text: "T. payout to stripe",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "msf_payout_from_stripe",
      text: "msf payout from stripe",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
  ];
  const columnsSuper = [
    {
      dataField: "date",
      text: "Date",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => (
        <>
          <span>{moment(e).format("DD-MM-YYYY")}</span>
        </>
      ),
    },
    {
      dataField: "invoice_created",
      text: "T. Invoices Created",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "franchise_name",
      text: "Fran Name",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "invoice_success",
      text: "T. Inv Success",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "revenue_collected",
      text: "T. Revenue Collected from Cust.(£)",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => (
        <span>
          {new Intl.NumberFormat("en-UK", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(e)}
        </span>
      ),
    },
    {
      dataField: "payment_success",
      text: "T. Payment Success",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "payment_failure",
      text: "T. Payment Failure (Open/Void/Failed)",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "creditnote_processed",
      text: "T. Credit Notes Processed",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "refund_amount",
      text: "T. Refunds Amount",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "franchise_payment_processed",
      text: "T. Franc. Payments Processed",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "franchise_payment_amount",
      text: "T. Fran. Payments Amount(£)",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => (
        <span>
          {new Intl.NumberFormat("en-UK", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(e)}
        </span>
      ),
    },
    {
      dataField: "msf_payment_processed",
      text: "T. MSF+ Processed",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "msf_payment_amount",
      text: "T. MSF+ Processed Amount(£)",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => (
        <span>
          {new Intl.NumberFormat("en-UK", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(e)}
        </span>
      ),
    },
    {
      dataField: "qbo_invoice_sync_success",
      text: "T. Invoices Synced to Fran.QBO",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "qbo_invoice_sync_failure",
      text: "T. Invoices Sync Failure to fran. QBO",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "qbo_creditnote_sync_success",
      text: "T. Credit Note Synced to Fran.QBO",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "qbo_creditnote_sync_failure",
      text: "T. Credit Note Sync Failure to fran. QBO",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "qbo_bill_sync_success",
      text: "T. MSF+ Bill Synced to Fran.QBO",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "qbo_bill_sync_failure",
      text: "T. MSF+ Bill Sync Failure to fran. QBO",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "es_qbo_invoice_sync_success",
      text: "es qbo invoice sync success",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "es_qbo_invoice_sync_failed",
      text: "es qbo invoice sync failed",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "es_qbo_creditnote_sync_success",
      text: "es qbo creditnote sync success",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "es_qbo_creditnote_sync_failed",
      text: "es qbo creditnote sync failed",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "total_payout_to_stripe",
      text: "T. payout to stripe",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
    {
      dataField: "msf_payout_from_stripe",
      text: "msf payout from stripe",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => <span>{e}</span>,
    },
  ];
  // const [total, setTotal] = useState(5);

  // useEffect(() => {
  //   if (TasksUIProps.queryParams?.pageNumber) {
  //     setTotal(total + 5);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [TasksUIProps.queryParams.pageNumber]);

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: TasksUIProps.queryParams?.pageSize,
    page: TasksUIProps.queryParams?.pageNumber,
    offset: entities,
    ids: TasksUIProps?.ids,
    setIds: TasksUIProps.setIds,
  };
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log("row", row);
      window.open(`order-details/${row.order_id}`, "_blank");
      // history.push(`/order-details/${row.order_id}`);
    },
  };
  console.log("TasksUIProps", TasksUIProps);
  const selectRow = {
    mode: "checkbox",
    hideSelectAll: true,
    hideSelectColumn: true,
  };
  console.log("entities", entities);
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={loading}
              paginationProps={paginationProps}
              entities={entities}
              customePagination={true}
              loading={loading}
            >
              {show && (
                <BootstrapTable
                  hover
                  wrapperClasses="table-responsive"
                  bordered={false}
                  rowEvents={rowEvents}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                  remote
                  keyField="invoice"
                  // defaultSorted={uiHelpers.defaultSorted}
                  data={!entities ? [] : entities}
                  onTableChange={getHandlerTableChange(
                    TasksUIProps.setQueryParams
                  )}
                  columns={
                    role && role?.length && role[0] === "franchise-owner"
                      ? columns
                      : columnsSuper
                  }
                  {...paginationTableProps}
                ></BootstrapTable>
              )}
              {/* {permission?.task?.view ? (
                <>
                  {(entities && entities?.length) || loading ? (
                    ""
                  ) : (
                    <NoRecord />
                  )}
                </>
              ) : (
                ""
                // <NotAuthorized />
              )} */}
            </Pagination>
          );
        }}
      </PaginationProvider>
      {snack.call ? <SnackBarTool {...snack} /> : ""}

      <ContactDealTaskModal dialog={dialog} setDialog={setDialog} />
    </>
  );
};

export default InvoiceReportTable;
