import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";

const NotificationModal = ({
  dialog,
  setDialog,
  header,
  mainText,
  handleChange,
  handleSendUrlLink,
  loading,
}) => {
  const intl = useIntl();
  const [notiData, setNotiData] = useState(null);
  return (
    <Modal show={dialog.open} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>{mainText}</span>
        <div>
          <label>Description</label>
          <div className="form-groupB">
            <textarea
              className="form-control"
              placeholder="Description"
              name="last_name"
              value={notiData}
              onChange={(e) => setNotiData(e.target.value)}
              rows={4} // You can adjust the number of rows as needed
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setDialog({ open: false })}
            className="btn btn-light btn-elevate"
          >
            {intl.formatMessage({ id: "No" })}
          </button>
          <> </>
          <button
            type="button"
            onClick={() => {
              handleChange(dialog.id, notiData);
            }}
            className="btn btn-delete btn-elevate"
          >
            {intl.formatMessage({ id: "Yes" })}
            {loading && <span className="ml-2 mr-2 spinner "></span>}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default NotificationModal;
