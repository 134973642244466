import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { EditOrderTableAction } from "./EditOrderTableAction";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { EditOrderTableButtons } from "./EditOrderTableButtons";
import BillingCycleEditOrderModal from "./Modal/BillingCycleEditOrderModal";
import DeleteIcon from "@material-ui/icons/Delete";
import { convertGBPtoPoundSymbol } from "../../../../utils/commonFunction";
import { LoadingSpinner } from "../../../../../_metronic/_partials/controls/LoadingSpinner";
import { insuranceCoverList } from "../../_redux/order/orderCrud";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { MenuItem, Select } from "@material-ui/core";
import ChangePlanModal from "./Modal/ChangePlanModal";
import { InputGroup } from "react-bootstrap";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";
import moment from "moment";
import { calculatePriceAccToProduct } from "../../EditOrderUtils";

export const EditOrderList = ({
  editOrderDetail,
  orderUIContext,
  state,
  dispatchReducer,
  phases,
  setCallUpComing,
  orderSummaryListStripe,
}) => {
  const [dialog, setDialog] = useState({ check: false });
  const handleUnitPrice = (value, index, check) => {
    console.log("value", value, check);

    const phaseIndex = state.phases.findIndex((e) => e.is_edit);

    let phase = state.phases.find((e) => e.is_edit);

    // Create a shallow copy of the items array
    const arr = phase.items.map((item, i) =>
      i === index ? { ...item, unit_price: +value } : item
    );

    // Update the state immutably
    if (check) {
      dispatchReducer({
        type: "SET_DATA",
        data: {
          ...state,
          insurance_price: value,
          phases: state.phases.map((phase, i) =>
            i === phaseIndex ? { ...phase, items: arr } : phase
          ),
        },
      });
      return;
    }
    dispatchReducer({
      type: "SET_DATA",
      data: {
        ...state,
        phases: state.phases.map((phase, i) =>
          i === phaseIndex ? { ...phase, items: arr } : phase
        ),
      },
    });
  };

  const savePrice = async () => {
    try {
      const price = await calculatePriceAccToProduct(
        orderSummaryListStripe?.allProducts,
        process.env.REACT_APP_PRODUCT_FIRST_CLASS,
        12
      );
      console.log("price", price); // Use the price variable as needed
      // You can now use `price` for further logic
    } catch (error) {
      console.error("Error calculating price:", error);
    }
  };

  savePrice();

  const handleInsurance = (targetValue) => {
    const podProtect =
      orderSummaryListStripe &&
      orderSummaryListStripe.allProducts &&
      orderSummaryListStripe.allProducts.find(
        (e) => e.stripe_product_id === process.env.REACT_APP_PRODUCT_POD_PROTECT
      );
    const unitAmount = podProtect && podProtect.product_prices[0].unit_amount;
    const value = targetValue / 1000;
    const price = unitAmount * value;
    console.log("kholi-0", price);

    const stateData = JSON.parse(JSON.stringify(state));
    const phaseArr = [...stateData.phases];
    const phase = phaseArr.find((e) => e.is_edit);
    const item = phase.items.find(
      (e) => e.product_id === process.env.REACT_APP_PRODUCT_POD_PROTECT
    );
    item.unit_price = price;
    dispatchReducer({
      type: "SET_DATA",
      data: {
        ...state,
        phases: phaseArr,
        insurance_cover: targetValue,
        insurance_price: price,
      },
    });
  };

  console.log("state-900", state);

  const handleUpdateQuantity = (value, index, productId) => {
    const price = calculatePriceAccToProduct(
      orderSummaryListStripe?.allProducts,
      productId,
      value
    );
    // const diviPrice = price/value
    const actualPrice = price / value;
    const phaseIndex = state.phases.findIndex((e) => e.is_edit);

    let phase = state.phases.find((e) => e.is_edit);

    // Create a shallow copy of the items array
    const arr = phase.items.map((item, i) =>
      i === index
        ? { ...item, quantity: +value, unit_price: +actualPrice.toFixed(2) }
        : item
    );
    console.log("productId", arr, value);

    // Update the state immutably
    dispatchReducer({
      type: "SET_DATA",
      data: {
        ...state,
        phases: state.phases.map((phase, i) =>
          i === phaseIndex ? { ...phase, items: arr } : phase
        ),
      },
    });
  };
  const items = Array.from({ length: 51 }, (_, index) => index * 1000);
  const handleCouponRemoval = (phases, id) => {
    // Map through each phase
    const updatedPhases = phases.map((phase) => {
      if (phase.is_edit) {
        // Filter out the discount where the coupon matches the provided id
        const updatedDiscounts = phase.discounts.filter(
          (discount) => discount.coupon !== id
        );
        // Return the updated phase with the filtered discounts
        return {
          ...phase,
          discounts: updatedDiscounts,
        };
      }
      return phase;
    });

    // Dispatch updated phases to the reducer
    dispatchReducer({
      type: "SET_DATA",
      data: {
        ...state,
        phases: updatedPhases,
        is_call_upcoming: true,
      },
    });
  };
  return (
    <div className="card-body pt-0">
      <div className="py-4">
        {" "}
        {/* {orderUIContext?.state?.productArray &&
          orderUIContext?.state?.productArray.length === 0 && (
            <LoadingSpinner />
          )} */}
        <div>
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">Duration</span>
          </h3>
        </div>
        <div className="col-lg-5  d-flex order-flt mb-4">
          <InputGroup className="calendr">
            <DatePicker
              fullWidth
              fixedHeight
              disabled
              className="form-control"
              wrapperClassName="datepicker"
              dateFormat="dd-MM-yyyy"
              placeholderText="From Date"
              selected={phases?.start_date * 1000}
              startDate={phases?.start_date * 1000}
              endDate={phases?.end_date * 1000}
              // selectsRange
              // isClearable
              onChange={(dates) => {
                // const [start, end] = dates;
                const phaseIndex = state.phases.findIndex((e) => e.is_edit);
                const arr = [...state.phases];
                arr[phaseIndex].start_date = Math.floor(
                  moment(dates).valueOf() / 1000
                );
                dispatchReducer({
                  type: "SET_DATA",
                  data: {
                    ...state,
                    phases: arr,
                  },
                });
                // setFieldValue(
                //   "from_date",
                //   dates ? moment(dates).format("yyyy-MM-DD") : ""
                // );

                // if (dates === null) {
                //   handleSubmit();
                // }
              }}
            />

            <CalendarTodayIcon />
          </InputGroup>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <InputGroup className="calendr">
            <DatePicker
              fullWidth
              fixedHeight
              disabled={!phases.is_add_phase}
              className="form-control"
              wrapperClassName="datepicker"
              dateFormat="dd-MM-yyyy"
              placeholderText="To Date"
              selected={phases?.end_date * 1000}
              startDate={phases?.start_date * 1000}
              endDate={phases?.end_date * 1000}
              // selectsRange
              onChange={(dates) => {
                const phaseIndex = state.phases.findIndex((e) => e.is_edit);
                const arr = [...state.phases];
                arr[phaseIndex].end_date = Math.floor(
                  moment(dates).valueOf() / 1000
                );
                dispatchReducer({
                  type: "SET_DATA",
                  data: {
                    ...state,
                    phases: arr,
                  },
                });
              }}
            />

            <CalendarTodayIcon />
          </InputGroup>
        </div>
        <div>
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">Pricing</span>
          </h3>
        </div>
        <Table responsive className="summerytable customColor">
          <thead>
            <tr>
              <th style={{ width: "40%" }}>PRODUCT</th>

              <th className="text-right w-25">PRICE</th>
              <th style={{ width: "15%" }}>QTY</th>
              <th className="text-right w-25">TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {phases?.items?.map((item, index) => {
              console.log("phasesssss", phases);
              return (
                <tr key={index}>
                  <td>
                    <div className="d-flex">{item?.product_name}</div>
                    {item?.service_period_days
                      ? "On subscription creation"
                      : ""}
                    <br />
                    {item?.service_period_days
                      ? "Count " + item?.service_period_days
                      : ""}
                  </td>
                  <td className="text-right">
                    <input
                      type="number"
                      className="form-control removeArrow"
                      value={item?.unit_price}
                      onChange={
                        (e) =>
                          handleUnitPrice(
                            e.target.value,
                            index,
                            item?.product_id ===
                              process.env.REACT_APP_PRODUCT_POD_PROTECT
                          )
                        // handleUpdateProduct(e.target.value, "price", item.id)
                      }
                      onBlur={() =>
                        dispatchReducer({
                          type: "SET_DATA",
                          data: {
                            ...state,
                            is_call_upcoming: true,
                          },
                        })
                      }
                    />
                  </td>
                  <td>
                    {item?.product_id ===
                    process.env.REACT_APP_PRODUCT_POD_PROTECT ? (
                      <span className="w-80 d-flex ml-3">
                        <Select
                          name="address"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                          className={`form-control marginCustom11  width116 `}
                          IconComponent={(props) => (
                            <i {...props}>
                              <KeyboardArrowDownIcon />
                            </i>
                          )}
                          inputProps={{ "aria-label": "Without label" }}
                          displayEmpty
                          value={state?.insurance_cover}
                          onChange={(e) => {
                            handleInsurance(e.target.value);
                          }}
                        >
                          {items.map((item) => {
                            if (item === 0) return;
                            return (
                              <MenuItem key={item} value={item}>
                                &pound;
                                {item.toLocaleString("en-IN")}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </span>
                    ) : (
                      <input
                        type="number"
                        className="form-control removeArrow"
                        value={
                          item?.quantity
                            ? String(item?.quantity).replace(/^0+/, "")
                            : 0
                        }
                        onChange={
                          (e) => {
                            handleUpdateQuantity(
                              e.target.value,
                              index,
                              item?.product_id
                            );
                          }
                          // handleUpdateProduct(
                          //   e.target.value,
                          //   "quantity",
                          //   item.id,
                          //   item?.pricing_model
                          // )
                        }
                        onBlur={() =>
                          dispatchReducer({
                            type: "SET_DATA",
                            data: {
                              ...state,
                              is_call_upcoming: true,
                            },
                          })
                        }
                      />
                    )}
                  </td>
                  <td className="text-right">
                    <input
                      type="number"
                      className="form-control removeArrow"
                      value={Number(
                        Number(item?.unit_price || 0) * item?.quantity
                      )?.toFixed(2)}
                      disabled
                      // onChange={(e) =>
                      //   handleUpdateProduct(e.target.value, "price", item.id)
                      // }
                      // onBlur={() =>
                      //   orderUIContext?.handleBreakDownApiCall(
                      //     orderUIContext?.state
                      //   )
                      // }
                    />
                  </td>
                  <td>
                    <EditOrderTableAction
                      setDialog={setDialog}
                      subItem={item}
                      orderUIContext={orderUIContext}
                      editOrderDetail={editOrderDetail}
                      state={state}
                      dispatchReducer={dispatchReducer}
                      index={index}
                      // value={value}
                    />
                  </td>
                </tr>
              );
            })}

            {state?.phases
              .find((e) => e.is_edit)
              .discounts?.map((item, index) => (
                <tr style={{ backgroundColor: "#ecf9ee" }}>
                  <td>
                    <div className="mb-2  d-flex">
                      <LocalOfferIcon className="flipIconFont15 mt-1" />
                      <strong className="ml-2">{item?.coupon}</strong>
                    </div>
                    <div className="ml-8">
                      {/* <p className="mb-1">{item?.id}</p> */}
                      <p className="mb-1">applied</p>
                      {/* <p className="mb-1">Applicable for 3 months on lineitems</p> */}
                    </div>
                  </td>

                  <td className="d-flex"></td>
                  <td></td>

                  <td>
                    <DeleteIcon
                      className="flipIconFont15 mb-1 "
                      onClick={() =>
                        handleCouponRemoval(state.phases, item?.coupon)
                      }
                    />
                    {/* {item?.isRemove && ( */}

                    {/* {/* )} */}
                  </td>
                </tr>
              ))}
            <EditOrderTableButtons
              orderUIContext={orderUIContext}
              state={state}
              editOrderDetail={editOrderDetail}
              dispatchReducer={dispatchReducer}
            />
          </tbody>
        </Table>
      </div>
      {dialog?.check && (
        <BillingCycleEditOrderModal
          dialog={dialog}
          setDialog={setDialog}
          orderUIContext={orderUIContext}
        />
      )}
      {dialog?.plan && (
        <ChangePlanModal
          state={state}
          dispatchReducer={dispatchReducer}
          dialog={dialog}
          setDialog={setDialog}
          orderUIContext={orderUIContext}
        />
      )}
    </div>
  );
};
