import { createSlice } from "@reduxjs/toolkit";

const initialInvoiceState = {
  list: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    productForEdit: undefined,
    lastError: null,
    loading: false,
  },
  listSub: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    productForEdit: undefined,
    lastError: null,
    loading: false,
  },
  listInvoiceReport: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    productForEdit: undefined,
    lastError: null,
    loading: false,
  },
  credit: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    productForEdit: undefined,
    lastError: null,
    loading: false,
  },
  searchTask: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const InvoiceSlice = createSlice({
  name: "invoice",
  initialState: initialInvoiceState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    //task list
    tasksFetched: (state, action) => {
      const { totalCount, entities, nextoffset, loading } = action.payload;
      state.list.listLoading = false;
      state.list.error = null;
      state.list.entities = entities;
      state.list.totalCount = totalCount;
      state.list.nextoffset = nextoffset;
      state.list.loading = loading;
    },
    subFetched: (state, action) => {
      const { totalCount, entities, nextoffset, loading } = action.payload;
      state.listSub.listLoading = false;
      state.listSub.error = null;
      state.listSub.entities = entities;
      state.listSub.totalCount = totalCount;
      state.listSub.nextoffset = nextoffset;
      state.listSub.loading = loading;
    },
    invoiceReportFetched: (state, action) => {
      const { totalCount, entities, nextoffset, loading } = action.payload;
      state.listInvoiceReport.listLoading = false;
      state.listInvoiceReport.error = null;
      state.listInvoiceReport.entities = entities;
      state.listInvoiceReport.totalCount = totalCount;
      state.listInvoiceReport.nextoffset = nextoffset;
      state.listInvoiceReport.loading = loading;
    },
    creditFetched: (state, action) => {
      const { totalCount, entities, nextoffset, loading } = action.payload;
      state.credit.listLoading = false;
      state.credit.error = null;
      state.credit.entities = entities;
      state.credit.totalCount = totalCount;
      state.credit.nextoffset = nextoffset;
      state.credit.loading = loading;
    },
    searchTask: (state, action) => {
      const { data } = action.payload;
      state.searchTask = data;
    },
    reset: (state) => {
      state.list = { ...initialInvoiceState.list };
      state.listSub = { ...initialInvoiceState.listSub };
      state.credit = { ...initialInvoiceState.credit };
      state.searchTask = [];
      state.error = null;
    },
  },
});
