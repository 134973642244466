import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { initIds } from "../../../../../_metronic/_partials/controls/RightPanel";
import { OrderInvoice } from "../../order-canva/OrderInvoice";
import { PaymentPlan } from "../../order-canva/PaymentPlan";
import { PartPayment } from "../../order-canva/PartPayment";
import { PutOnHold } from "../../order-canva/PutOnHold";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  fetchBillingCardDetail,
  fetchInvoiceList,
  fetchMyOrderDetailCard,
  fetchMyOrderTimeline,
  fetchOrderDropDown,
  fetchPaymentInvoice,
  fetchChargeBeeInvoiceList,
} from "../../_redux/order/orderAction";
import { EditNextBilling } from "../../order-canva/EditNextBilling";
import { AdhocCharge } from "../../order-canva/AdhocCharge";
import { useIntl } from "react-intl";
import CustomeDialog from "../../../../../_metronic/_partials/widgets/alert/CustomeDialog";
import BillingActionDropdown from "./BillingActionDropdown";
import CustomerBillingDetail from "./CustomerBillingDetail";
import { lateFees, resumeSubscription } from "../../_redux/order/orderCrud";
import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { CancelSubscription } from "../../order-canva/CancelSubscription";
import { Switch, CircularProgress } from "@material-ui/core";
import { OrderRecurring } from "../../order-canva/OrderRecurring";
import { Charge20 } from "../../order-canva/Charge";
import { LoadingCustome } from "../../../../../_metronic/_partials/controls/LoadingCustome";

export function CustomerBilling({
  billingCard,
  orderDetail,
  orderInvoice,
  cardDetailArray,
  paymentInvoice,
  hasArrear,
  setcallApiHas,
  setOpenInvoice,
  openInvoice,
}) {
  console.log("openInvoice-2", openInvoice);

  // Use the intl hook to handle translations
  const intl = useIntl();
  // Use the useState hook to initialize and update state
  const [check, setCheck] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialog, setDialog] = useState({ type: false });
  const [dialogRecur, setDialogRecur] = useState({ dialog: false });
  const [toggleCheck, setToggleCheck] = React.useState({
    checkedB: true,
  });
  const [eightCharge, setEightCharge] = useState(false);
  // const [openInvoice, setOpenInvoice] = useState({open:false})
  const [loading, setLoading] = useState(false);
  // Use the useDispatch hook to dispatch actions
  const dispatch = useDispatch();
  // Use the useSelector hook to access the invoiceList from the redux store
  const { invoiceList, chargeBeeInvoiceList } = useSelector(
    (state) => ({
      invoiceList: state.order.invoiceList,
      chargeBeeInvoiceList: state.order.chargeBeeInvoiceList,
    }),
    shallowEqual
  );

  console.log("chargeBeeInvoiceList", chargeBeeInvoiceList);
  console.log("billingCard", billingCard);
  console.log("orderInvoice", orderInvoice);
  console.log("paymentInvoice", paymentInvoice);

  // Initial array of ids
  const init = [
    "kt_OrderInvoice_panel",
    "kt_PaymentPlan_panel",
    "kt_Charge20_panel",
    "kt_Refund_panel",
    "kt_PartPayment_panel",
    "kt_CreditNotes_panel",
    "kt_PutOnHold_panel",
    "kt_EditNextBilling_panel",
    "kt_EditAdhocCharge_panel",
    "kt_CancelSubscription_panel",
    "kt_OrderRec_panel",
  ];

  // Use the useEffect hook to run the initIds function when the check state changes
  useEffect(() => {
    if (check) {
      initIds(init);
    }
  }, [check, init]);
  // Use the useEffect hook to fetch invoice data when the orderDetail.franchise_id changes
  useEffect(() => {
    if (orderDetail?.franchise_id && toggleCheck?.checkedB) {
      dispatch(
        fetchBillingCardDetail(
          orderDetail?.franchise_id,
          orderDetail?.subscription_data?.id,
          setSnack,
          orderDetail?.customer_chargebee_id,
          orderDetail?.id
        )
      );
    }
    if (orderDetail?.franchise_id && toggleCheck?.checkedB) {
      dispatch(
        fetchInvoiceList(
          orderDetail?.franchise_id,
          orderDetail?.id,
          orderDetail?.subscription_id
        )
      );
      dispatch(fetchOrderDropDown());
      dispatch(fetchChargeBeeInvoiceList(orderDetail?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail?.franchise_id, toggleCheck?.checkedB]);

  // Function to handle resuming a subscription
  const handleResumeSub = () => {
    setSnack({ call: false, type: "", message: "" });
    const data = {
      franchise_id: orderDetail?.franchise_id || "",
      order_id: orderDetail?.id || "",
      subscription_id: orderDetail?.subscription_id || "",
      unpaid_invoices_handling: "1",
    };
    if (dialog.check === "activate" || dialog.check === "stop") {
      const lateFessData = {
        subscription_id: orderDetail?.subscription_id || "",
        status: billingCard?.late_fee_status === 1 ? "0" : 1,
      };
      lateFees(lateFessData)
        .then((res) => {
          // Dispatch necessary actions to update the order detail and timeline
          dispatch(
            fetchBillingCardDetail(
              orderDetail?.franchise_id,
              orderDetail?.subscription_data?.id,
              setSnack,
              orderDetail?.customer_chargebee_id,
              orderDetail?.id
            )
          );
          dispatch(fetchMyOrderDetailCard(orderDetail?.id));
          dispatch(fetchMyOrderTimeline(orderDetail?.id));
          dispatch(
            fetchPaymentInvoice(
              orderDetail?.id,
              orderDetail?.franchise_id,
              orderDetail?.subscription_id
            )
          );
          setSnack({ call: true, type: "success", message: res.data.message });
          setDialog({ type: false });
        })
        .catch((error) => {
          setSnack({
            call: true,
            type: "error",
            message: error.response.data.message,
          });
        });
      return;
    }
    resumeSubscription(data)
      .then((res) => {
        // Dispatch necessary actions to update the order detail and timeline
        dispatch(fetchMyOrderDetailCard(orderDetail?.id));
        dispatch(fetchMyOrderTimeline(orderDetail?.id));
        dispatch(
          fetchPaymentInvoice(
            orderDetail?.id,
            orderDetail?.franchise_id,
            orderDetail?.subscription_id
          )
        );
        setSnack({ call: true, type: "success", message: res.data.message });
        setDialog({ type: false });
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };
  const value =
    (orderInvoice?.first_invoice &&
      orderInvoice?.first_invoice.length &&
      orderInvoice?.first_invoice[0]?.invoice) ||
    "";

  const handleChange = (event) => {
    setToggleCheck({
      ...toggleCheck,
      [event.target.name]: event.target.checked,
    });
  };
  // useEffect(() => {
  //   dispatch(fetchOrderDropDown());
  // }, [dispatch]);
  // Render the component
  return (
    <>
      <div
        className={`card card-custom gutter-b ${!toggleCheck.checkedB &&
          "smallCard"}`}
      >
        <div className="card-header align-items-center border-0 mt-4 mb-0">
          <div className="d-flex justify-content-between w-100">
            <h3 className="card-title align-items-start flex-column">
              <span className="font-weight-bolder text-dark">
                {intl.formatMessage({ id: "Billing" })}
                <Switch
                  checked={toggleCheck.checkedB}
                  onChange={handleChange}
                  color="primary"
                  name="checkedB"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </span>
            </h3>
            {toggleCheck.checkedB && (
              <div>
                <Dropdown
                  onClick={() => setCheck(check ? false : true)}
                  className="dropdown-inline"
                  drop="down"
                  alignRight
                >
                  <Dropdown.Toggle
                    id="dropdown-toggle-top2"
                    variant="transparent"
                    className="btn btn-light  font-size-sm font-weight-bolder dropdown-toggle text-dark-75"
                  >
                    {intl.formatMessage({ id: "Actions" })}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="dropdown-menu dropdown-menu-sm dropdown-menu-right"
                    style={{ width: "200px" }}
                  >
                    <BillingActionDropdown
                      intl={intl}
                      billingCard={billingCard}
                      setDialog={setDialog}
                      orderDetail={orderDetail}
                      setDialogRecur={setDialogRecur}
                      dialogRecur={dialogRecur}
                      setEightCharge={setEightCharge}
                      cardDetailArray={cardDetailArray}
                      openInvoice={openInvoice}
                      setOpenInvoice={setOpenInvoice}
                      chargeBeeInvoiceList={chargeBeeInvoiceList}
                      hasArrear={hasArrear}
                      setSnack={setSnack}
                      snack={snack}
                      setcallApiHas={setcallApiHas}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>
        {billingCard?.loading && (
          <div className="text-center mt-8">
            <CircularProgress />
          </div>
        )}
        {toggleCheck.checkedB && !billingCard?.loading && (
          <CustomerBillingDetail
            orderDetail={orderDetail}
            intl={intl}
            billingCard={billingCard}
            value={value}
            cardDetailArray={cardDetailArray}
          />
        )}
      </div>
      {openInvoice?.open && (
        <>
          <OrderInvoice
            invoiceList={invoiceList}
            paymentInvoiceList={paymentInvoice}
            openInvoice={openInvoice}
            setOpenInvoice={setOpenInvoice}
            chargeBeeInvoiceList={chargeBeeInvoiceList}
          />
          <div
            className="offcanvas-overlay"
            onClick={() => setOpenInvoice(false)}
          ></div>
        </>
      )}
      <PaymentPlan orderDetail={orderDetail} billingCard={billingCard} />
      <Charge20 orderDetail={orderDetail} billingCard={billingCard} />
      <PartPayment orderDetail={orderDetail} billingCard={billingCard} />
      <EditNextBilling orderDetail={orderDetail} billingCard={billingCard} />
      <PutOnHold orderDetail={orderDetail} />
      <CancelSubscription
        orderDetail={orderDetail}
        billingCard={billingCard}
        eightCharge={eightCharge}
      />
      <CustomeDialog
        header={
          dialog.check === "resume"
            ? "Resume Subscription"
            : dialog.check === "activate"
            ? "Activate Late Fees"
            : dialog.check === "stop"
            ? "Stop Late Fees"
            : ""
        }
        mainText={
          dialog.check === "activate"
            ? "Are you sure you want to activate late fees flow?"
            : dialog.check === "stop"
            ? "Are you sure you want to stop adding any more late fees?"
            : dialog.check === "stop"
            ? "Are you sure you want to stop adding any more late fees?"
            : ""
        }
        dialog={dialog}
        setDialog={setDialog}
        handleAction={handleResumeSub}
      />
      <AdhocCharge orderDetail={orderDetail} />
      {loading && <LoadingCustome />}

      {snack.call ? <SnackBarTool {...snack} /> : ""}
      {dialogRecur?.dialog ? (
        <>
          <OrderRecurring
            dialogRecur={dialogRecur}
            setDialogRecur={setDialogRecur}
          />
          <div
            className="offcanvas-overlay"
            onClick={() => setDialogRecur({ dialog: false })}
          ></div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
