import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { capitalizeFirstLetter } from "../../../../utils/canvaCloseFunction";
import { convertGBPtoPoundSymbol } from "../../../../utils/commonFunction";
import { offlinePayment, podStatus } from "../../_redux/order/orderCrud";
import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { useDispatch } from "react-redux";
import {
  fetchBillingCardDetail,
  fetchMyOrderDetailCard,
  fetchMyOrderTimeline,
  fetchPaymentInvoice,
} from "../../_redux/order/orderAction";
import moment from "moment";
import { object } from "prop-types";

const CustomerBillingDetail = ({
  orderDetail,
  intl,
  billingCard,
  value,
  cardDetailArray,
}) => {
  const dispatch = useDispatch();
  const [offlineData, setOfflineData] = useState({ amount: "" });
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [podData, setPodData] = useState(null);
  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await podStatus(orderDetail?.id);
      console.log("response", response);
      setPodData(response.data.data);
      // ...
    }
    if (orderDetail?.is_container != 1) {
      fetchData();
    }
  }, []);
  const handleOfflinePayment = () => {
    setLoading(true);
    setSnack({ call: false, type: "", message: "" });
    const data = {
      franchise_id: orderDetail?.franchise_id || "",
      invoice_id: billingCard?.old_unpaid_invoice?.invoice?.id || "",
      amount: offlineData?.amount || "",
      order_id: orderDetail?.id || "",
      type:
        orderDetail?.payment_method === "bac"
          ? "bank_transfer"
          : orderDetail?.payment_method,
    };
    offlinePayment(data)
      .then((res) => {
        setLoading(false);
        setSnack({ call: true, type: "success", message: res.data.message });
        setTimeout(() => {
          dispatch(fetchMyOrderDetailCard(orderDetail?.id));
          dispatch(
            fetchBillingCardDetail(
              orderDetail?.franchise_id,
              orderDetail?.subscription_data?.id,
              "",
              orderDetail?.customer_chargebee_id,
              orderDetail?.id
            )
          );
          dispatch(fetchMyOrderTimeline(orderDetail?.id));
          dispatch(
            fetchPaymentInvoice(
              orderDetail?.id,
              orderDetail?.franchise_id,
              orderDetail?.subscription_id
            )
          );
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };
  console.log("billingCard", billingCard);

  console.log("cardDetail Arrrrr", cardDetailArray);

  const primaryCard = () => {
    if (cardDetailArray && cardDetailArray.length) {
      return cardDetailArray.find(
        (e) => e.is_primary_card == "1" || e.is_customer_primary_card == "1"
      );
    }
    return undefined;
  };

  const primary = primaryCard();
  console.log("Primary", primary);

  return (
    <div className="card-body pt-0 pad15">
      <div>
        <div className="row mt-5">
          <div className="col-md-6 mb-2">
            {Number(orderDetail?.pod) > 1
              ? orderDetail?.is_container === 1
                ? "Container"
                : "Pods"
              : orderDetail?.is_container === 1
              ? "Container"
              : "Pod"}
            :
          </div>
          <div className="col-md-6 mb-2">
            {orderDetail?.is_container === 1 ? (
              orderDetail?.container_size
            ) : (
              <>{orderDetail?.pod || "-"}&nbsp;</>
            )}
          </div>
          <div className="col-md-6 mb-2">
            Number of {orderDetail?.is_container === 1 ? "Container" : "Pods"}{" "}
            Initially Booked:
          </div>
          <div className="col-md-6 mb-2">
            {value?.line_items &&
            value?.line_items.length &&
            value?.line_items?.some((e) => e.entity_type === "plan_item_price")
              ? value?.line_items?.find(
                  (e) => e.entity_type === "plan_item_price"
                )?.quantity
              : billingCard?.pods_booked
              ? billingCard?.pods_booked
              : "-"}
          </div>
          <div className="col-md-6 mb-2">
            Number of {orderDetail?.is_container === 1 ? "Container" : "Pods"}{" "}
            Collected:
          </div>
          <div className="col-md-6 mb-2">
            {billingCard?.pods_collected ? billingCard?.pods_collected : "0"}
          </div>
          <div className="col-md-6 mb-2">
            Number of {orderDetail?.is_container === 1 ? "Container" : "Pods"}{" "}
            Currently in Store:
          </div>
          <div className="col-md-6 mb-2">
            {billingCard?.pods_in_store ? billingCard?.pods_in_store : "0"}
          </div>
          <div className="col-md-6 mb-2">
            {intl.formatMessage({ id: "Payment.Status" })}:{" "}
          </div>
          <div className="col-md-6 mb-2">
            {billingCard?.payment_status && (
              <>
                <span
                  className={`svg-icon  ${
                    billingCard?.payment_status === "1"
                      ? "svg-icon-success svg-icon-lg "
                      : "svg-icon-danger closeicon svg-icon-md"
                  }`}
                >
                  {billingCard?.payment_status === "1" ? (
                    <SVG
                      title=" "
                      className="h-25 align-self-end"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Done-circle.svg"
                      )}
                    ></SVG>
                  ) : (
                    <SVG
                      title=" "
                      className="h-25 align-self-end"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Navigation/Minus.svg"
                      )}
                    ></SVG>
                  )}
                </span>{" "}
                <span className="text-danger">
                  {billingCard?.in_arrears === "0" ||
                  billingCard?.in_arrears === 0
                    ? ""
                    : `In arrears : ${
                        billingCard?.currency_code
                          ? convertGBPtoPoundSymbol(billingCard?.currency_code)
                          : ""
                      } ${billingCard?.in_arrears || "-"}`}
                </span>
              </>
            )}
          </div>
          <div className="col-md-6 mb-2">
            {intl.formatMessage({ id: "Recurring.Cycle" })}:
          </div>
          <div className="col-md-6 mb-2 minusicon">
            <span
              className={`svg-icon ${
                orderDetail?.is_is_return !== 1
                  ? "svg-icon-success svg-icon-lg"
                  : "svg-icon-danger closeicon svg-icon-md "
              }`}
            >
              {orderDetail?.is_return !== 1 ? (
                <SVG
                  title=" "
                  className="h-25 align-self-end"
                  src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}
                ></SVG>
              ) : (
                <SVG
                  title=" "
                  className="h-25  align-self-end"
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Minus.svg")}
                ></SVG>
              )}
            </span>{" "}
          </div>
          <div className="col-md-6 mb-2">
            {intl.formatMessage({ id: "Invoice.Date" })}:
          </div>
          <div className="col-md-6 mb-2">
            {billingCard?.invoice_date || "-"}
          </div>
          <div className="col-md-6 mb-2">
            {intl.formatMessage({ id: "Next.Billing.Amount" })}:
          </div>
          <div className="col-md-6 mb-2">
            {billingCard?.currency_code
              ? convertGBPtoPoundSymbol(billingCard?.currency_code)
              : ""}

            {billingCard?.next_billing_amount || "-"}
          </div>
          <div className="col-md-6 col-sm-6 col-xxl-6 mb-2">
            {intl.formatMessage({ id: "Next.billing.period" })}:
          </div>
          <div className="col-md-6 col-sm-6 col-xxl-6 mb-2">
            {billingCard?.next_billing_period || "-"}
          </div>
          <div className="col-md-6 mb-2">Payment Method:</div>
          <div className="col-md-6 mb-2">
            {orderDetail?.payment_method
              ? // orderDetail?.payment_method === "bac" && orderDetail?.is_container === 1
                //   ? "Card"
                //   :
                orderDetail?.payment_method === "bac"
                ? "Bank Transfer"
                : orderDetail?.payment_method === "cash"
                ? "Cash"
                : orderDetail?.payment_method === "card"
                ? "Card"
                : orderDetail?.payment_method
              : "Card"}
          </div>
          {(orderDetail?.subscription_data &&
            orderDetail.subscription_data.subscription &&
            orderDetail.subscription_data.subscription?.pause_collection !==
              null) ||
          orderDetail?.subscription_data?.status === "canceled" ||
          orderDetail?.subscription_data?.status === "completed" ? (
            <>
              <div className="col-md-6 mb-2">Subscription Status:</div>
              <div className="col-md-6 mb-2" style={{ color: "red" }}>
                {/* {orderDetail?.subscription_data?.status === "released"
                  ? "Cancelled"
                  : ""} */}
                {orderDetail?.subscription_data?.status
                  ? capitalizeFirstLetter(
                      orderDetail?.subscription_data?.status === "completed"
                        ? "Cancelled"
                        : orderDetail?.subscription_data?.status
                    )
                  : ""}
              </div>
            </>
          ) : (
            <>
              <div className="col-md-6 mb-2">Subscription Status:</div>
              <div className="col-md-6 mb-2">
                {/* {orderDetail?.subscription_data?.status === "released"
                  ? "Cancelled"
                  : ""} */}
                {billingCard?.status
                  ? capitalizeFirstLetter(
                      billingCard?.status === "completed"
                        ? "Cancelled"
                        : billingCard?.status === "trialing"
                        ? "Active"
                        : billingCard?.status
                    )
                  : "-"}
              </div>
            </>
          )}
          {orderDetail?.is_container == "1" ? (
            <>
              <div className="col-md-6 mb-2">Container Status:</div>
              <div className="col-md-6 mb-2">
                {orderDetail?.container_status == "1"
                  ? "Reserved"
                  : orderDetail?.container_status == "2"
                  ? "Confirmed"
                  : orderDetail?.container_status == "3"
                  ? "In Storage"
                  : orderDetail?.container_status == "4"
                  ? "Completed"
                  : orderDetail?.container_status == "5"
                  ? "Cancelled"
                  : "-"}
              </div>
            </>
          ) : (
            ""
          )}
          {/* <div className="mt-4 col-12 ">
            <label className="checkbox">
              <input
                type="checkbox"
                name="enabled_for_checkout"
                // checked={formik.values?.paymentType === "cash" ? true : false}
                // onChange={(e) => formik.setFieldValue("paymentType", "cash")}
              />
              <span className="mr-2"></span>
              Payment Received
            </label>
          </div>{" "} */}
          {/* {billingCard?.in_arrears !== 0 &&
          (orderDetail?.payment_method === "cash" ||
            orderDetail?.payment_method === "bac") ? (
            <div className="mt-4 col-12  ">
              <label>Amount</label>
              <div className="row">
                <div className=" col-6 ">
                  <input
                    type="Text"
                    placeholder={`${convertGBPtoPoundSymbol("GBP")} Amount`}
                    className="form-control"
                    name="enabled_for_checkout"
                    value={offlineData?.amount}
                    onChange={(e) => {
                      if (e.target.value <= billingCard?.in_arrears) {
                        setOfflineData({ amount: e.target.value });
                      }
                    }}
                  />
                </div>
                <div className=" col-6 ">
                  <button
                    type="button"
                    className="btn btn-primary font-weight-bolder font-size-sm "
                    onClick={handleOfflinePayment}
                    disabled={loading}
                  >
                    Save
                    {loading && (
                      <span className="ml-2 mr-2 spinner spinner-white"></span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )} */}
          <div className="col-md-6 col-sm-6 col-xxl-6 mb-2">
            Auto Collection:
          </div>
          <div className="col-md-6 col-sm-6 col-xxl-6 mb-2">
            {billingCard?.subscription_auto_collection === null ||
            billingCard?.subscription_auto_collection === ""
              ? `Same as Customer (${billingCard?.customer_auto_collection &&
                  capitalizeFirstLetter(
                    billingCard?.customer_auto_collection
                  )})`
              : billingCard?.subscription_auto_collection
              ? billingCard?.subscription_auto_collection &&
                capitalizeFirstLetter(billingCard?.subscription_auto_collection)
              : ""}
          </div>
          <div className="col-md-6 mb-2">Billing Address:</div>
          <div className="col-md-6 mb-2">
            {(primary && primary.billing_details?.address?.line1) || "-"}
          </div>
          {orderDetail?.return_reason && (
            <>
              <div className="col-md-6 mb-2">Reason for Return:</div>
              <div className="col-md-6 mb-2">{orderDetail?.return_reason}</div>
            </>
          )}
          {orderDetail?.return_reason_description && (
            <>
              <div className="col-md-6 mb-2">Description:</div>
              <div className="col-md-6 mb-2">
                {orderDetail?.return_reason_description}
              </div>
            </>
          )}
          <div className="col-md-6 mb-2">Exempt from Price Inc:</div>
          <div className="col-md-6 mb-2">
            {orderDetail?.is_exempt_price == 1 ? "True" : "False"}
          </div>
          {orderDetail?.yearly_price_scheduled_date && (
            <>
              <div className="col-md-6 mb-2">Date of Price Inc Scheduled:</div>
              <div className="col-md-6 mb-2">
                {orderDetail?.yearly_price_scheduled_date
                  ? moment(orderDetail?.yearly_price_scheduled_date).format(
                      "DD-MM-yyyy"
                    )
                  : ""}
              </div>
            </>
          )}
          {orderDetail?.price_increase_by_amount && (
            <>
              <div className="col-md-6 mb-2">Price Increase By</div>
              <div className="col-md-6 mb-2">
                &pound;{orderDetail?.price_increase_by_amount}
              </div>
            </>
          )}
          {orderDetail?.is_container < 1 &&
            orderDetail?.twenty_percent_status == 1 &&
            podData?.eighty_percent_status < 1 && (
              <>
                <div className="col-md-6 mb-2">Paid today:</div>
                <div className="col-md-6 mb-2">
                  &pound;{podData?.twenty_percent_amount || "-"}
                </div>
                <div className="col-md-6 mb-2">Due:</div>
                <div className="col-md-6 mb-2">
                  &pound;
                  {podData?.eighty_percent_amount +
                    "/will be collected 48 hours ahead of your collection day" ||
                    "-"}
                </div>
              </>
            )}
        </div>
      </div>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
};

export default CustomerBillingDetail;
