/* eslint-disable no-restricted-imports */
import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TransferTableModal from "../order-canva/TransferTableModal";
import moment from "moment";
import { getTransferDetails } from "../_redux/order/orderCrud";
import { LoadingSpinner } from "../../../../_metronic/_partials/controls/LoadingSpinner";
import { Tooltip } from "@material-ui/core";
import BalanceAdjustModal from "../order-canva/BalanceAdjustModal";

const useStyles = makeStyles({
  table: {
    minWidth: 350,
  },
  tooltip: {
    fontSize: "1rem",
  },
});

const BalanceAdjustmentTable = ({
  balanceList,
  transferList,
  setPagination,
  pagination,
  traLoading,
  orderDetail,
}) => {
  console.log("balanceList", balanceList);

  const classes = useStyles();
  const [dialog, setDialog] = useState({
    modal: false,
    data: {},
    loading: false,
  });
  const handleOpenModal = (e, credit_balance) => {
    setDialog({
      ...dialog,
      modal: true,
      data: e,
      credit_balance,
      loading: true,
      id: orderDetail?.id,
    });
    // getTransferDetails(id)
    //   .then((res) => {
    //     setDialog({
    //       ...dialog,
    //       modal: true,
    //       data: res.data.data,
    //       loading: false,
    //     });
    //   })
    //   .catch((err) => {
    //     setDialog({ ...dialog, modal: false, data: {}, loading: false });
    //   });
  };

  const handleCloseModal = () => {
    setDialog({ ...dialog, modal: false });
  };

  const handleNextPage = () => {
    if (transferList?.has_more && transferList?.payments?.length > 0) {
      console.log("lastPayment", transferList);
      const lastPayment =
        transferList.payments[transferList.payments.length - 1];

      setPagination((prev) => ({
        ...prev,
        starting_after: lastPayment.id, // Send the last payment ID for the next page
        currentPage: prev.currentPage + 1,
        ending_before: "", // Clear ending_before when moving forward
      }));
    }
  };

  // Handle Previous Page
  const handlePreviousPage = () => {
    if (transferList?.payments?.length > 0) {
      const firstPayment = transferList.payments[0]; // Get the first payment from the current list
      setPagination((prev) => ({
        ...prev,
        ending_before: firstPayment.id, // Send the first payment ID for the previous page
        currentPage: prev.currentPage - 1,
        starting_after: "", // Clear starting_after when moving backward
      }));
    }
  };

  return (
    <>
      <div className="card card-custom gutter-b customer-duration ">
        <TableContainer component={Paper}>
          {traLoading ? (
            <LoadingSpinner />
          ) : (
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>id </TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Ending balance </TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {balanceList?.list?.data?.length > 0 &&
                  balanceList?.list?.data.map((e, index) => {
                    return (
                      <TableRow
                        key={index}
                        onClick={() => {
                          handleOpenModal(e, balanceList?.credit_balance);
                        }}
                      >
                        <TableCell>&nbsp;{e.id || "-"}</TableCell>

                        <TableCell>
                          &pound;&nbsp;{+e.amount / 100 || "-"}
                        </TableCell>
                        <TableCell>
                          &pound;&nbsp;{+e.ending_balance / 100 || "-"}
                        </TableCell>

                        <TableCell>{e?.customer || "-"}</TableCell>
                        <TableCell>{e.description || "-"}</TableCell>
                        <TableCell>
                          {moment(e?.created * 1000).format("DD-MM-YYYY")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              <div className="pagination-buttons">
                <button
                  type="button"
                  className="btn btn-light btn-elevate"
                  onClick={handlePreviousPage}
                  disabled={
                    !pagination?.starting_after && !pagination?.ending_before
                      ? true
                      : pagination?.ending_before && !transferList.has_more
                      ? true
                      : false
                  } // Disable the button if there's no previous data
                >
                  {"<"}
                </button>
                <span>&nbsp;&nbsp;{pagination.currentPage}&nbsp;&nbsp;</span>{" "}
                {/* Display current page number */}
                <button
                  type="button"
                  className="btn btn-light btn-elevate"
                  onClick={handleNextPage}
                  disabled={
                    pagination?.starting_after &&
                    !pagination?.ending_before &&
                    !transferList.has_more
                      ? true
                      : false
                  } // Disable the button if no more pages
                >
                  {">"}
                </button>
              </div>
            </Table>
          )}
        </TableContainer>
        <BalanceAdjustModal dialog={dialog} setDialog={setDialog} />
      </div>
    </>
  );
};

export default BalanceAdjustmentTable;
