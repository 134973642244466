import React, { useState } from "react";
import { Form, Modal, Card } from "react-bootstrap";
import { LoadingSpinner } from "../../../../_metronic/_partials/controls/LoadingSpinner";
import { setAdjustBalance } from "../_redux/order/orderCrud";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { useDispatch } from "react-redux";
import { fetchBalaceList } from "../_redux/order/orderAction";

const BalanceAdjustModal = ({ dialog, setDialog }) => {
  const [formData, setFormData] = useState({ amount: 0, description: "" });
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    setSnack({ call: false, type: "", message: "" });
    setLoading(true);

    const data = {
      ...formData,
      order_id: dialog?.id,
    };

    setAdjustBalance(data)
      .then((res) => {
        dispatch(fetchBalaceList(dialog?.id));
        setSnack({ call: true, type: "success", message: res.data.message });
        setLoading(false);
        setTimeout(() => {
          setSnack({ call: false, type: "", message: "" });
          setDialog({ modal: false });
        }, 3000);
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message,
        });
        setLoading(false);
      });
  };

  return (
    <Modal
      show={dialog.modal}
      aria-labelledby="example-modal-sizes-title-lg"
      className="modal-style"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Credit Balance Adjustment
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="container">
          <div style={{ display: "flex", gap: "6px" }}>
            <Card
              className="mb-4 p-3 card-shadow"
              style={{ textAlign: "center" }}
            >
              <Card.Body>
                <h6>Starting Balance:</h6>
                <span>
                  <strong>&pound;{dialog?.credit_balance}</strong>
                </span>
              </Card.Body>
            </Card>

            <Card className="mb-4 p-3 card-shadow">
              <Card.Body>
                <h6>New Balance:</h6>
                <p>
                  <strong>
                    &pound;
                    {Number(
                      Number(dialog?.credit_balance) + Number(formData?.amount)
                    ).toFixed(2)}
                  </strong>
                </p>
              </Card.Body>
            </Card>
          </div>

          <Form.Group className="mb-5">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter adjustment amount"
              name="amount"
              value={formData.amount}
              onChange={(e) =>
                setFormData({ ...formData, amount: e.target.value })
              }
            />
          </Form.Group>

          <Form.Group className="mb-5">
            <Form.Label>Add Internal Note</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Enter a note"
              name="description"
              value={formData.description}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
            />
          </Form.Group>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light btn-elevate"
          onClick={() => setDialog({ modal: false })}
        >
          Close
        </button>
        <button
          type="button"
          onClick={handleSubmit}
          disabled={loading}
          className="btn btn-primary font-weight-bolder font-size-sm"
        >
          Save
          {loading && <span className="ml-3 spinner spinner-white"></span>}
        </button>
      </Modal.Footer>

      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </Modal>
  );
};

export default BalanceAdjustModal;
