/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import { FormControl, MenuItem, Select } from "@material-ui/core/";
import React, { useEffect, useMemo, useState } from "react";
import {
  fetchInvoiceList,
  fetchMyOrderTimeline,
  fetchOrderDropDown,
  fetchOrderInvoice,
  fetchPaymentInvoice,
} from "../_redux/order/orderAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import DiscardDialog from "../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import { Form } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { RefundSchema } from "../order-form/validationSchema";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import {
  creditNotes,
  paymentsTimelineItems,
  refund,
  refundCredit,
} from "../_redux/order/orderCrud";
import { useFormik } from "formik";
import { Checkbox } from "@material-ui/core";
import { LoadingSpinner } from "../../../../_metronic/_partials/controls/LoadingSpinner";

export function Refund({ setCanva, canva, orderDetailData }) {
  const dispatch = useDispatch();

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialogDis, setDialogDis] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itemsList, setItemsList] = useState([]);
  const [itemsLoading, setItemsLoading] = useState(false);

  const { orderDetail } = useSelector(
    (state) => ({
      orderDetail: state.order.orderDropDown,
    }),
    shallowEqual
  );

  const initialValues = {
    franchise_id: canva?.data?.franchise_id || "",
    invoice_id: canva?.data?.invoice_id || "",
    // amount:
    //   canva?.data.invoice_type === "Credit Note" &&
    //   canva?.data?.status === "refund_due"
    //     ? canva?.data?.amount_available
    //     : canva?.data?.amount || "",
    reason_code: "",
    notes: "",
    type: "refundable",
    order_id: canva?.data?.order_id || "",
    currency_code: canva?.data?.currency || "",
    items: canva?.data?.items,
  };

  const handleSubmit = (values, actions) => {
    setLoading(true);
    setSnack({ call: false, type: "", message: "" });
    const editedItems = values.items
      .filter((item) => item.is_edited)
      .map((item) => ({
        ...item,
        quantity: item.quantity_editable === true ? item.quantity : 0,
      }));
    const filteredValues = {
      ...values,
      items: editedItems,
    };
    // const refundFun =
    //   canva?.data?.invoice_type === "Credit Note" &&
    //   canva?.data?.status === "refund_due"
    //     ? refundCredit
    //     : refund;
    // const creditData = {
    //   credit_note_id: values.invoice_id,
    //   amount: values.amount,
    //   order_id: values.order_id,
    //   franchise_id: values.franchise_id,
    //   comment: values.notes,
    // };
    creditNotes(
      filteredValues
      // canva?.data?.invoice_type === "Credit Note" ? creditData : values
    )
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        setTimeout(() => {
          setCanva({ type: false });
        }, 2000);
        setLoading(false);
        dispatch(
          fetchPaymentInvoice(
            canva?.data?.order_id,
            canva?.data?.franchise_id,
            orderDetailData?.subscription_id
          )
        );
        dispatch(fetchOrderInvoice(canva?.data?.order_id));
        dispatch(
          fetchInvoiceList(
            canva?.data?.franchise_id,
            canva?.data?.order_id,
            orderDetailData?.subscription_id
          )
        );
        dispatch(fetchMyOrderTimeline(canva?.data?.order_id));
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleReset = () => {};

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema:
      // canva?.data?.invoice_type === "Credit Note" &&
      // canva?.data?.status === "refund_due"
      canva?.data?.invoice_type === "Credit Note" ? false : RefundSchema,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });
  const handlePaymentItems = (id) => {
    setItemsLoading(true);
    paymentsTimelineItems(id)
      .then((res) => {
        setItemsLoading(false);

        setItemsList(res.data.data.items);
        console.log("res.data.data.items", res.data.data.items);
        const updatedItems = res.data.data.items.map((item) => ({
          ...item,
          maxAmountCheck: item.amount,
        }));
        formik.setFieldValue("items", updatedItems);
      })
      .catch(() => {
        setItemsLoading(false);
      });
  };

  useEffect(() => {
    if (!itemsList.length) {
      handlePaymentItems(canva?.data?.invoice_id);
    }
  }, []);
  useEffect(() => {
    dispatch(fetchOrderDropDown());
  }, [dispatch]);
  const handleClose = () => {
    if (formik.dirty && Object.keys(formik.touched).length !== 0) {
      setDialogDis(true);
    } else {
      setCanva({ type: "" });
      formik.resetForm();
    }
  };

  const handleChange = (event, index) => {
    const { checked } = event.target;

    // Update formik values to add or update the `is_edited` key for the specific item
    const updatedItems = formik.values.items.map((item, idx) =>
      idx === index
        ? { ...item, is_edited: checked } // Add or update `is_edited` for the current item
        : item
    );
    console.log("updatedItems", updatedItems);

    formik.setFieldValue("items", updatedItems);
  };
  const totalEditedAmount = useMemo(() => {
    return formik?.values?.items
      ?.filter((item) => item.is_edited) // Filter items where is_edited is true
      .reduce((sum, item) => {
        // Calculate discounted amount if discount_rate exists
        const discount = item.discount_rate
          ? (item.discount_rate / 100) * item.amount
          : 0;
        const finalAmount = item.amount - discount;
        return Number(sum) + Number(finalAmount);
      }, 0); // Sum the discounted amounts
  }, [formik?.values?.items]);
  return (
    <>
      {/*begin::Header*/}

      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>Refund</h5>
          </div>
          <div
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10"
            id="kt_quick_panel_logs_kt_Refund_panel"
          >
            <div>
              {/* <Form.Group className="mb-5">
                <Form.Label>
                  Refund Amount for{" "}
                  {canva?.data?.invoice_type === "Credit Note" &&
                  canva?.data?.status === "refund_due"
                    ? "Credit note"
                    : "invoice"}{" "}
                  #{canva?.data?.invoice_id || "-"}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={canva?.data?.currency || ""}
                  className={`form-control  ${getInputClasses("amount")}`}
                  name="amount"
                  {...formik.getFieldProps("amount")}
                />
                {formik.touched.amount && formik.errors.amount ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.amount}</div>
                  </div>
                ) : null}
              </Form.Group> */}
            </div>
            {canva?.data?.invoice_type === "Credit Note" &&
            canva?.data?.status === "refund_due" ? (
              ""
            ) : (
              <div>
                <Form.Group className="mb-5">
                  <Form.Label>Reason code</Form.Label>
                  <FormControl fullWidth>
                    <Select
                      name="reason_code"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      className={`form-control w-100 ${getInputClasses(
                        "reason_code"
                      )}`}
                      {...formik.getFieldProps("reason_code")}
                    >
                      <MenuItem value="">Reason Code</MenuItem>
                      {orderDetail &&
                      orderDetail?.reasonCodeList &&
                      orderDetail.reasonCodeList.length &&
                      orderDetail.reasonCodeList.length > 0 ? (
                        orderDetail.reasonCodeList.map((item, index) => (
                          <MenuItem value={item?.id} key={index}>
                            {item?.title}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="no"></MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  {formik.touched.reason_code && formik.errors.reason_code ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.reason_code}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
              </div>
            )}
            <div>
              <Form.Group className="mb-5">
                {/* <Form.Label>Reason code</Form.Label> */}
                <Form.Control
                  as="textarea"
                  rows="3"
                  placeholder="Add a note (optional)"
                  className={`form-control  ${getInputClasses("notes")}`}
                  name="notes"
                  // {...formik.getFieldProps("notes")}
                  onChange={(e) => {
                    formik.setFieldValue("notes", e.target.value);
                  }}
                />
                {formik.touched.notes && formik.errors.notes ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.notes}</div>
                  </div>
                ) : null}
              </Form.Group>
            </div>
            {itemsLoading && <LoadingSpinner />}
            {formik.values.items?.length > 0 &&
              formik.values.items.map((item, index) => {
                return (
                  <div className="row" key={index}>
                    <div
                      className="col-1"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "12px",
                      }}
                    >
                      <Checkbox
                        checked={item?.is_edited ? true : false}
                        onChange={(e) => {
                          handleChange(e, index);
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                    <Form.Group
                      className=" col-3"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {/* <Form.Label>Name</Form.Label> */}
                      <div style={{ marginTop: "36px" }}>
                        <span>{item.name}</span>

                        {item?.discount_rate && item?.discount_rate ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {item?.discount_name}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {/* <Form.Control
                        type="text"
                        placeholder={""}
                        className={`form-control  ${getInputClasses(
                          `items[${index}].name`
                        )}`}
                        name={`items[${index}].name`}
                        value={item.name}
                        onChange={formik.handleChange}
                      /> */}
                    </Form.Group>
                    <Form.Group className="mb-5 col-2">
                      <Form.Label>Unit Price</Form.Label>
                      <Form.Control
                        type="text"
                        className={`form-control  ${getInputClasses(
                          `items[${index}].unit_amount`
                        )}`}
                        name={`items[${index}].unit_amount`}
                        value={item.unit_amount}
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group className="mb-5 col-2">
                      <Form.Label>Qty</Form.Label>
                      <Form.Control
                        type="text"
                        className={`form-control  ${getInputClasses(
                          `items[${index}].quantity`
                        )}`}
                        name={`items[${index}].quantity`}
                        value={item.quantity}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `items[${index}].quantity`,
                            itemsList[index].quantity < e.target.value
                              ? itemsList[index].quantity
                              : e.target.value
                          );
                          formik.setFieldValue(
                            `items[${index}].amount`,
                            itemsList[index].quantity < e.target.value
                              ? itemsList[index].amount
                              : Number(e.target.value) *
                                  Number(item.unit_amount)
                          );
                        }}
                        disabled={!item?.quantity_ebitable}
                      />
                      {formik.touched.items?.[index]?.quantity &&
                      formik.errors.items?.[index]?.quantity ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.items[index].quantity}
                          </div>
                        </div>
                      ) : null}
                      {/* Button to update value on click */}
                    </Form.Group>
                    <Form.Group className="mb-5 col-2">
                      <Form.Label>Total</Form.Label>
                      <Form.Control
                        type="number"
                        className={`form-control  ${getInputClasses(
                          `items[${index}].amount`
                        )}`}
                        name={`items[${index}].amount`}
                        value={Number(item.amount)}
                        disabled={!item?.amount_editable}
                        onChange={(e) => {
                          const inputValue = parseFloat(e.target.value) || 0;
                          formik.setFieldValue(
                            `items[${index}].amount`,
                            itemsList[index].amount < inputValue
                              ? itemsList[index].amount
                              : inputValue
                          );
                          formik.setFieldValue(`items[${index}].quantity`, "0");
                        }}
                      />
                      {item?.discount_rate && item?.discount_rate ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            -&pound;
                            {Number(
                              (item.amount * item?.discount_rate) / 100
                            ).toFixed(2)}
                          </div>
                        </div>
                      ) : null}
                    </Form.Group>
                    <Form.Group className="mb-5 col-2">
                      <Form.Label>Actual&nbsp;Amount</Form.Label>
                      <Form.Control
                        type="number"
                        className={`form-control ${getInputClasses(
                          `items[${index}].acAmount`
                        )}`}
                        name={`items[${index}].acAmount`}
                        value={formik.values.items[index].acAmount || ""}
                        onChange={(e) => {
                          function calculateActualAmount(
                            finalAmount,
                            discountRate
                          ) {
                            const actualAmount =
                              finalAmount / (1 - discountRate / 100);
                            return actualAmount;
                          }
                          const inputValue = parseFloat(e.target.value) || 0;
                          // const discountAmount =
                          //   (inputValue *
                          //     formik.values.items[index]?.discount_rate) /
                          //   100;
                          const actualAmount = calculateActualAmount(
                            inputValue,
                            formik.values.items[index]?.discount_rate
                          );

                          // Calculate the new amount based on the maximum limit
                          const maxAmount = formik.values.items[index].amount;
                          // const finalAmount = Math.min(actualAmount, maxAmount);

                          formik.setFieldValue(
                            `items[${index}].acAmount`,
                            inputValue
                          );

                          // if (actualAmount <= maxAmount) {
                          //   formik.setFieldValue(
                          //     `items[${index}].amount`,
                          //     +actualAmount.toFixed(2)
                          //   );
                          // }
                          console.log(
                            "maxAmount",
                            actualAmount,
                            formik.values.items[index]
                          );
                          formik.setFieldValue(
                            `items[${index}].amount`,
                            +actualAmount >
                              +formik.values.items[index]?.maxAmountCheck
                              ? +formik.values.items[index]?.maxAmountCheck
                              : +actualAmount.toFixed(2)
                          );

                          formik.setFieldValue(`items[${index}].quantity`, "0");
                        }}
                      />
                    </Form.Group>
                    {formik.values.items?.length - 1 == index ? (
                      <>
                        <div
                          className="mr-4"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <span className="mr-4">Subtotal</span>
                          <span>
                            &pound;{Number(totalEditedAmount).toFixed(2)}
                          </span>
                        </div>
                        <div
                          className="mr-4"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <span className="mr-4">Total amount to credit</span>
                          <span>
                            &pound;{Number(totalEditedAmount).toFixed(2)}
                          </span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right  mr-4">
            <button
              type="button"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => handleClose()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={loading}
            >
              Refund
              {loading && <span className="spinner spinner-white mr-4"></span>}
            </button>
          </div>
        </div>
      </form>
      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        setCanva={setCanva}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
