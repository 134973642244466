/* eslint-disable no-restricted-imports */
import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TransferTableModal from "../order-canva/TransferTableModal";
import moment from "moment";
import { getTransferDetails } from "../_redux/order/orderCrud";
import { LoadingSpinner } from "../../../../_metronic/_partials/controls/LoadingSpinner";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 350,
  },
  tooltip: {
    fontSize: "1rem",
  },
});

const TransferTable = ({
  transferList,
  setPagination,
  pagination,
  traLoading,
}) => {
  const classes = useStyles();
  const [dialog, setDialog] = useState({
    modal: false,
    data: {},
    loading: false,
  });
  const handleOpenModal = (id) => {
    setDialog({ ...dialog, modal: true, data: {}, loading: true });
    getTransferDetails(id)
      .then((res) => {
        setDialog({
          ...dialog,
          modal: true,
          data: res.data.data,
          loading: false,
        });
      })
      .catch((err) => {
        setDialog({ ...dialog, modal: false, data: {}, loading: false });
      });
  };

  const handleCloseModal = () => {
    setDialog({ ...dialog, modal: false });
  };

  console.log("pagination", pagination);
  const handleNextPage = () => {
    if (transferList?.has_more && transferList?.payments?.length > 0) {
      console.log("lastPayment", transferList);
      const lastPayment =
        transferList.payments[transferList.payments.length - 1];

      setPagination((prev) => ({
        ...prev,
        starting_after: lastPayment.id, // Send the last payment ID for the next page
        currentPage: prev.currentPage + 1,
        ending_before: "", // Clear ending_before when moving forward
      }));
    }
  };

  // Handle Previous Page
  const handlePreviousPage = () => {
    if (transferList?.payments?.length > 0) {
      const firstPayment = transferList.payments[0]; // Get the first payment from the current list
      setPagination((prev) => ({
        ...prev,
        ending_before: firstPayment.id, // Send the first payment ID for the previous page
        currentPage: prev.currentPage - 1,
        starting_after: "", // Clear starting_after when moving backward
      }));
    }
  };

  return (
    <>
      <div className="card card-custom gutter-b customer-duration ">
        <TableContainer component={Paper}>
          {traLoading ? (
            <LoadingSpinner />
          ) : (
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status </TableCell>
                  <TableCell>Reason </TableCell>
                  <TableCell>Payment Method</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transferList?.payments?.length > 0 &&
                  transferList?.payments.map((e, index) => {
                    return (
                      <TableRow
                        key={index}
                        onClick={() => {
                          handleOpenModal(e.id);
                        }}
                      >
                        <TableCell>&pound;&nbsp;{e.amount || "-"}</TableCell>
                        {e?.status_message ? (
                          <TableCell>
                            <Tooltip
                              title={e?.status_message}
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <span
                                className={`label label-lg label-light-${
                                  e.status === "succeeded"
                                    ? "success"
                                    : e.status === "Medium"
                                    ? "warning"
                                    : e.status === "Low"
                                    ? "danger"
                                    : "danger"
                                } label-inline`}
                              >
                                {e.status ? e.status : "-"}
                              </span>
                            </Tooltip>
                          </TableCell>
                        ) : (
                          <TableCell>
                            {" "}
                            <span
                              className={`label label-lg label-light-${
                                e.status === "succeeded"
                                  ? "success"
                                  : e.status === "Medium"
                                  ? "warning"
                                  : e.status === "Low"
                                  ? "danger"
                                  : "danger"
                              } label-inline`}
                            >
                              {e.status ? e.status : "-"}
                            </span>
                          </TableCell>
                        )}
                        <TableCell className="w-25">
                          {e?.status_message && e.status
                            ? e.status_message
                            : "-"}
                        </TableCell>
                        <TableCell>{e?.payment_method || "-"}</TableCell>
                        <TableCell>{e.description || "-"}</TableCell>
                        <TableCell>
                          {moment(e?.created_at * 1000).format("DD-MM-YYYY")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              <div className="pagination-buttons">
                <button
                  type="button"
                  className="btn btn-light btn-elevate"
                  onClick={handlePreviousPage}
                  disabled={
                    !pagination?.starting_after && !pagination?.ending_before
                      ? true
                      : pagination?.ending_before && !transferList.has_more
                      ? true
                      : false
                  } // Disable the button if there's no previous data
                >
                  {"<"}
                </button>
                <span>&nbsp;&nbsp;{pagination.currentPage}&nbsp;&nbsp;</span>{" "}
                {/* Display current page number */}
                <button
                  type="button"
                  className="btn btn-light btn-elevate"
                  onClick={handleNextPage}
                  disabled={
                    pagination?.starting_after &&
                    !pagination?.ending_before &&
                    !transferList.has_more
                      ? true
                      : false
                  } // Disable the button if no more pages
                >
                  {">"}
                </button>
              </div>
            </Table>
          )}
        </TableContainer>
        <TransferTableModal dialog={dialog} setDialog={setDialog} />
      </div>
    </>
  );
};

export default TransferTable;
