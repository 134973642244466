import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";

// import Calcumate from "./pages/Calcumate";
import CalendarPage from "./modules/Calendar/CalendarPage";
import { ContactDetails } from "./modules/ContactDeals/page/ContactDetails";
import { ContactPage } from "./modules/ContactDeals/page/ContactPage";
import { CustomerPage } from "./modules/Customer/CustomerPage";
import { CustomersDetails } from "./modules/Customer/page/CustomersDetails";
import { DashboardPage } from "./pages/DashboardPage";
import { Deals } from "./modules/ContactDeals/page/Deals";
import { DealsPage } from "./modules/ContactDeals/page/DealsPage";
import { NewOrder } from "./modules/Order/NewOrder/NewOrderPage";

import { OrderPage } from "./modules/Order/OrderPage";
import { OrdersDetails } from "./modules/Order/OrdersDetailsPage";
import { ReportingListPage } from "./modules/Reporting/ReportingListPage";
import { ReportingPage } from "./modules/Reporting/ReportingPage";
import { Settings } from "./pages/Settings";
import { TasksPage } from "./modules/Tasks/TasksPage";
import { QuotesPage } from "./modules/Quotes/QuotesPage";
import SettingList from "./modules/Settings/pages/SettingPages";
import TermsAndCondition from "./pages/TermsAndCondition";
import { SuccessfulOrder } from "./modules/Order/components/SuccessfulOrder";
import { OrderEditPage } from "./modules/Order/OrderEditPage";
import { InvoicePage } from "./modules/Invoice/InvoicePage";
import { CreditPage } from "./modules/Invoice/CreditPage";
import { OrdersDetailStripe } from "./modules/Order/order-stripe/OrderDetailStripePage";
import PaymentPage from "./modules/demoPage";
import { NewOrderStripe } from "./modules/Order/NewOrderStripe/NewOrderStripePage";
import { EditOrderStripe } from "./modules/Order/EditOrderStripe";
import { InvoiceReportPage } from "./modules/Invoice/InvoiceReportPage";

// const SettingList = lazy(() => import("./modules/Settings/pages/SettingPages"));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/orders" />
        }
        <ContentRoute exact path="/dashboard" component={DashboardPage} />
        {/* {permission?.setting?.view ? ( */}
        <ContentRoute exact path="/setting-page" component={Settings} />
        <ContentRoute
          exact
          path="/terms-and-condition"
          component={TermsAndCondition}
        />
        {/* ) : (
          ""
        )} */}
        <ContentRoute path="/settings" component={SettingList} />

        <ContentRoute exact path="/deals" component={DealsPage} />
        <ContentRoute exact path="/online-quotes" component={QuotesPage} />

        <ContentRoute exact path="/deal-view/:id" component={Deals} />

        <ContentRoute exact path="/contacts" component={ContactPage} />
        <ContentRoute exact path="/invoices" component={InvoicePage} />
        <ContentRoute exact path="/sub" component={CreditPage} />
        <ContentRoute
          exact
          path="/invoice-report"
          component={InvoiceReportPage}
        />

        <ContentRoute
          exact
          path="/contact-details/:id"
          component={ContactDetails}
        />

        {/* <ContentRoute exact path="/new-order" component={NewOrder} /> */}
        <ContentRoute
          exact
          path="/new-order-stripe"
          component={NewOrderStripe}
        />
        <ContentRoute
          exact
          path="/successful-order/:id"
          component={SuccessfulOrder}
        />
        <ContentRoute exact path="/customers" component={CustomerPage} />
        <ContentRoute
          exact
          path="/customer-details/:id"
          component={CustomersDetails}
        />
        <ContentRoute exact path="/orders" component={OrderPage} />
        <ContentRoute exact path="/create-return" component={OrderPage} />
        <ContentRoute exact path="/create-access" component={OrderPage} />

        <ContentRoute exact path="/reporting" component={ReportingPage} />
        <ContentRoute
          exact
          path="/reporting/:id"
          component={ReportingListPage}
        />
        <ContentRoute exact path="/calendar" component={CalendarPage} />
        <ContentRoute
          exact
          path="/order-details/:id"
          component={OrdersDetails}
        />
        <ContentRoute
          exact
          path="/order-details-stripe/:id"
          component={OrdersDetailStripe}
        />
        {/* <ContentRoute exact path="/edit-order/:id" component={OrderEditPage} /> */}
        <ContentRoute
          exact
          path="/edit-order/:id"
          component={EditOrderStripe}
        />

        <ContentRoute exact path="/tasks" component={TasksPage} />
        <ContentRoute exact path="/stripe" component={PaymentPage} />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
