import moment from "moment";
import React, { useState } from "react";
import { downloadInvoice } from "../../../../app/modules/Order/_redux/order/orderCrud";
import { LoadingCustome } from "../../controls/LoadingCustome";
import SnackBarTool from "../snack-bar/SnackBar";
import { useIntl } from "react-intl";
import NoRecord from "../NoRecord";
import { convertGBPtoPoundSymbol } from "../../../../app/utils/commonFunction";
export function Payments({ className, paymnetInvoiceList }) {
  const intl = useIntl();
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadInvoice = (franchise, invoice, type, orderId) => {
    setIsLoading(true);
    downloadInvoice(franchise, invoice, type, orderId)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });

        setIsLoading(false);
        window.open(res.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };
  return (
    <>
      {isLoading ? <LoadingCustome /> : ""}
      <div className={`card card-custom  ${className}`}>
        <div className="card-header align-items-center border-0 mt-4">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">
              {intl.formatMessage({ id: "Payments" })}
            </span>
            {/* <span className="text-muted mt-3 font-weight-bold font-size-sm">
              890,344 Sales
            </span> */}
          </h3>
        </div>
        <div className="card-body pt-4 timelinesect">
          <div
            className={
              paymnetInvoiceList && paymnetInvoiceList.length > 0
                ? "timeline timeline-6s mt-3 timee "
                : ""
            }
          >
            <div className="timelinelist">
              {paymnetInvoiceList && paymnetInvoiceList.length > 0 ? (
                paymnetInvoiceList.map((item, index) => {
                  const data =
                    item?.status?.charAt(0).toUpperCase() +
                    item?.status?.slice(1);
                  const status = data?.split("_");
                  return (
                    <div
                      className="timeline-item align-items-start"
                      key={index}
                    >
                      <div className="timeline-labels font-weight-bolder text-dark-75 font-size-lg">
                        {(item?.created_at &&
                          moment(item?.created_at).format("DD-MM-YYYY")) ||
                          "-"}
                      </div>

                      <div className="timeline-badge">
                        <i className="fa fa-genderless text-primary icon-xl"></i>
                      </div>

                      <div className="d-flex justify-content-between row ml-1 w-100">
                        <div className="font-weight-bolder font-size-lg  text-dark-75 pl-3 col-md-12 col-xxl-7 ">
                          {item?.invoice_name || "-"}
                          <p
                            className="font-size-sm font-weight-normal text-dark-25"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleDownloadInvoice(
                                item?.franchise_id,
                                item?.invoice_id,
                                item?.invoice_type,
                                item?.order_id
                              )
                            }
                          >
                            #{item?.booking_reference || "-"}
                          </p>
                        </div>
                        <div className="font-weight-bolder font-size-lg  text-dark-75 pl-3 col-xxl-5 col-md-12">
                          {item?.currency
                            ? convertGBPtoPoundSymbol(item?.currency)
                            : ""}

                          {item?.amount || "0.00"}
                          <p className="font-size-sm font-weight-normal text-dark-25">
                            {status.map((data) => data + " ")}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <NoRecord />
              )}
            </div>
          </div>
        </div>
      </div>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
