import { findDistance } from "../../orderUtils";
import * as requestFromServer from "./orderCrud";
import { orderSlice, callTypes } from "./orderSlice";

const { actions } = orderSlice;

export const fetchOrder = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findOrder(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.orderFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchSearchNewOrder = (queryParams, controllerRef) => (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.customer }));
  return requestFromServer
    .findNewSearchOrder(queryParams, controllerRef)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.searchNewOrderFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.customer }));
    });
};
export const fetchNewCustomerCard = (queryParams, fId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.detail }));

  return requestFromServer
    .findNewCustomerCard(queryParams)
    .then((response) => {
      console.log("response for data", response);
      const { data } = response.data;
      if (
        fId &&
        fId !== (data && data.length > -1 && data[0]?.contact?.franchise_id)
      ) {
        window.location.reload();
      }
      dispatch(actions.newCustomerFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.detail }));
    });
};

export const fetchSearchProduct = (queryParams, value, paramData, setSnack) => (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.searchProductFetched({ loading: true, data: [] }));
  return requestFromServer
    .findSearchProduct(queryParams, value, paramData)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.searchProductFetched({ loading: false, data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      setSnack({
        call: true,
        type: "error",
        message: error.response.data.message,
      });
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      dispatch(actions.searchProductFetched({ loading: false, data: [] }));
    });
};

export const fetchOrderSummary = (
  deleteProduct,
  check,
  unit,
  period,
  productId,
  franchise,
  postcode,
  handleAddProduct,
  type,
  qty,
  delete_base_product,
  data,
  packaging,
  setSnack,
  setIsLoading
) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.orderSummaryFetched({ loading: true, data: [] }));
  if (packaging === "packaging") {
    setIsLoading(true);
  }

  return requestFromServer
    .findOrderSummary(
      deleteProduct,
      check,
      unit,
      period,
      productId,
      franchise,
      postcode,
      qty,
      delete_base_product,
      data
    )
    .then((response) => {
      const { data, data_two } = response.data;
      dispatch(actions.orderSummaryFetched({ loading: false, data, data_two }));
      if (type) {
        handleAddProduct(data, productId);
      }
      if (packaging === "packaging") {
        setSnack({
          call: true,
          type: "success",
          message: "Packaging material added successfully ",
        });
        setIsLoading(false);
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.orderSummaryFetched({ loading: false, data: [] }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      if (packaging === "packaging") {
        setIsLoading(false);
      }
    });
};

export const fetchQuoteOrderData = (queryParams, quote, setSnack) => (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.quoteData({ data: {}, loading: true }));
  return requestFromServer
    .findQuoteOrder(queryParams, quote)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.quoteData({ data, loading: false }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      setSnack({
        call: true,
        type: "error",
        message: error.response.data.message,
      });
      dispatch(actions.quoteData({ data: {}, loading: false }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//order page listing
export const fetchMyOrderList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(
    actions.myOrderFetchedList({
      totalCount: "",
      entities: [],
      listLoading: true,
    })
  );
  return requestFromServer
    .findMyOrderList(queryParams)
    .then((response) => {
      const { data, meta } = response.data;

      dispatch(
        actions.myOrderFetchedList({
          totalCount: meta.total,
          entities: data,
          listLoading: false,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//my order detail
export const fetchMyOrderDetailCard = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findMyOrderDetailCard(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.OrderDetailFetched({ data }));
    })
    .catch((error) => {
      // if (error?.response?.status === 404 || error?.response?.status === 400) {
      //   // Redirect to the error page
      //   window.location.href = "/error/error-v1"; // Replace '/error' with the actual route of your error page
      // }
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchTransferTable = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.traLoading }));
  return requestFromServer
    .findTransferCard(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.TransferListFetched({ data }));
    })
    .catch((error) => {
      // if (error?.response?.status === 404 || error?.response?.status === 400) {
      //   // Redirect to the error page
      //   window.location.href = "/error/error-v1"; // Replace '/error' with the actual route of your error page
      // }
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.traLoading }));
    });
};

//order detail on edit order
export const fetchEditOrderDetailCard = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findEditOrderDetailCard(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.OrderEditDetailFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//my order timeline

export const fetchMyOrderTimeline = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findMyOrderTimeline(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.OrderTimelineFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//card detail
export const fetchCardDetail = (queryParams, charge, subId, order) => (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.CardDetail({ data: [], loading: true }));
  return requestFromServer
    .findCardDetail(queryParams, charge, subId, order)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.CardDetail({ data, loading: false }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.CardDetail({ data: [], loading: false }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//billing card detail api
export const fetchBillingCardDetail = (queryParams, sub, setSnack, id, oId) => (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.BillingCardDetail({ data: {}, loading: true }));
  return requestFromServer
    .findBillingCardDetail(queryParams, sub, id, oId)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.BillingCardDetail({ data, loading: false }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      dispatch(actions.BillingCardDetail({ data: {}, loading: false }));
      setSnack({
        call: true,
        type: "error",
        message: error.response?.data?.message,
      });
    });
};

//payment timeline
export const fetchPaymentInvoice = (
  queryParams,
  fra,
  subId,
  is_subscription_id,
  invoice_id
) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPaymentInvoice(queryParams, fra, subId, is_subscription_id, invoice_id)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.PaymentInvoiceList({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//invoice list
export const fetchInvoiceList = (queryParams, id, subId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findInvoiceList(queryParams, id, subId)
    .then((response) => {
      const { data } = response.data;

      dispatch(actions.InvoiceList({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      // setSnack({ call: true, type: "success", message: "" });
    });
};

//ChargeBeeInvoiceList
export const fetchChargeBeeInvoiceList = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findChargeBeeInvoiceList(id)
    .then((response) => {
      console.log("RESPONSE", response);
      const { data } = response.data;

      dispatch(actions.ChargeBeeInvoiceList({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      // setSnack({ call: true, type: "success", message: "" });
    });
};

//order Listing
//invoice list
export const fetchOrderDropDown = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findOrderDropDown()
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.OrderDropDown({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//orderSummary listing
export const fetchOrderSummaryList = (oID, poID) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findOrderSummaryList(oID, poID)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.OrderTableEdit({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//orderSummary listing
export const fetchOrderInvoice = (id, isAbort) => (dispatch) => {
  dispatch(actions.OrderInvoice({ data: {}, loading: true }));
  return requestFromServer
    .findOrderInvoice(id, isAbort)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.OrderInvoice({ data, loading: false }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.OrderInvoice({ data: {}, loading: false }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      throw error;
    });
};

//driver loader list
export const fetchDriverLoader = (id) => (dispatch) => {
  dispatch(actions.DriverLoader({ data: {}, loading: true }));
  return requestFromServer
    .findDriverOrder(id)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.DriverLoader({ data, loading: false }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.DriverLoader({ data: {}, loading: false }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//driver loader Saved list
export const fetchSavedDriverLoader = (id) => (dispatch) => {
  dispatch(actions.SavedDriverLoader({ data: {}, loading: true }));
  return requestFromServer
    .savedDriverLoader(id)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.SavedDriverLoader({ data, loading: false }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.SavedDriverLoader({ data: {}, loading: false }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//version
export const fetchVersion = (fId, oId, vId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.version }));
  return requestFromServer
    .findVersion(fId, oId, vId)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.versionDetail({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.version }));
    });
};

//packaging material action
export const fetchPakagingMaterialList = (fId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.packaging }));
  return requestFromServer
    .packagingList(fId)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.packagingMaterial({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.packaging }));
    });
};

//packaging material action
export const fetchBookingAccessCharges = (orderId, fid) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .BookingAccessCharage(orderId, fid)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.bookingAccessCharges({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find Chargers";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//state of orders on order table page
export const fetchStats = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.stats }));
  return requestFromServer
    .getStats()
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.statsSlice({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find Chargers";
      dispatch(actions.catchError({ error, callType: callTypes.stats }));
    });
};

export const fetchNearSite = (data, address) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.site }));
  return requestFromServer
    .nearSite(data)
    .then(async (response) => {
      const { data } = response.data;
      const arr = [];
      for (let item of data) {
        const distance = await findDistance(address, item?.address);
        const newObj = {
          ...item,
          distance: distance,
        };
        arr.push(newObj);
      }
      arr.sort((a, b) => a.distance - b.distance);
      dispatch(actions.siteSlice({ arr }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find Chargers";
      dispatch(actions.catchError({ error, callType: callTypes.site }));
    });
};

export const fetchNewFlags = (data) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.stats }));
  return requestFromServer
    .getNewFlag(data)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.newFlagSlice({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find Chargers";
      dispatch(actions.catchError({ error, callType: callTypes.stats }));
    });
};

//new-order-summary
export const fetchOrderSummaryListStripe = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.callApi }));
  return requestFromServer
    .orderSummaryList(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.orderSummaryListStripe({ data }));
      // dispatch(actions.orderSummaryListStripe({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.callApi }));
    });
};
// edit-order-summary
export const fetchEditOrderSummary = (data) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.sub }));
  return requestFromServer
    .editOrderSummary(data)
    .then((response) => {
      console.log("response", response);
      const { data } = response.data;
      dispatch(actions.editOrderSummaryFetched({ loading: false, data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't Process Api";
      dispatch(actions.editOrderSummaryFetched({ loading: false, data: [] }));
      dispatch(actions.catchError({ error, callType: callTypes.sub }));
    });
};

export const fetchBalaceList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findBalanceList(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.balanceListFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
